import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import green from '@material-ui/core/colors/green';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import CommonStyle from "../../Camponets/config/CommonStyle";
const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    display: 'block',
    margin: `${theme.spacing(3)}px 0`,
  },
  field: {
    margin: `${theme.spacing(3)}px 5px`,
  },
  formControl: {
    margin: theme.spacing(1),

  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  switchBase: {
    color: green[50],
    '&$checked': {
      color: green[500],
      '& + $bar': {
        backgroundColor: green[500],
      },
    },
  },
  bar: {},
  checked: {},
  size: {
    width: 40,
    height: 40,
  },
  sizeIcon: {
    fontSize: 20,
  },
});

function CommonSwitch(props) {
  const { onChange, checked,name, label, value, defaultValue, classes, helper } = props;
  return (
    <Fragment>
      <FormControl className={classes.formControl} aria-describedby="name-helper-text">
        <FormControlLabel

          className={checked && classes.ActiveSwithchStyle}
          control={(
            <Switch
            name={name}
              checked={checked}
              onChange={onChange}
              defaultChecked={defaultValue}
              value={value}
              color="primary"
            />
          )}
          label={label}
        />
        {helper && <FormHelperText id="name-helper-text">{helper}</FormHelperText>}
      </FormControl>
    </Fragment>
  );

}

CommonSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(CommonStyle)(CommonSwitch);