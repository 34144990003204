import { CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import * as APIController from '../../APIs/APIController.js';
import CommonButton from "../../Camponets/Button/CommonButton.js";
import DropDown from "../../Camponets/Select/DropDown.js";
import TextField from "../../Camponets/TextField/TextField.js";
import CommonSwitch from "../../Camponets/Switch/CommonSwitch.js";
import CheckBox from '../../Camponets/CheckBox/CheckBox.js';
import { json } from 'react-router-dom';

class MyApiForm extends React.Component {
    state = {
        ID: '',
        ProjectID: '',
        TableID: '',
        Name: "",
        CantrollerID: "",
        StoredProcedureID: "",
        SingularName: "",
        PluralName: "",
        EditID: '0',
        IsActive: true,
        ProjectOption: [],
        CantrollerOption: [],
        StoredProcedureIDOption: [],

        ApiOption: [],
        TableOption: [],
        IsValid: false,
        IsLoading: false,
        IsloadData: false
    }

    componentDidMount() {
        this.props.EditID == 0 ? this.wsGetProjectForDropdown() : this.wsGetTableByID(this.props.EditID);
    }

    wsGetProjectForDropdown() {
        APIController.GetProjectForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ProjectOption: response.object.data })
                    this.wsGetControllerForDropdown()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    wsGetControllerForDropdown() {
        APIController.GetControllerForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ CantrollerOption: response.object.data })

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    // wsGetTableForDropdown() {
    //     APIController.GetTableForDropdown()
    //         .then((response) => {
    //             if (response.error == null) {
    //                 this.setState({ TableOption: response.object.data })
    //             }
    //             else if (response.error.message && response.error.message.length > 0) {
    //                 this.props.handleNotification(true, response.error.message, 'error')
    //             }
    //         })
    // }

    handleChange = (e, event) => {
        if (e) {
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        } else if (event) {
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleValidate() {
        if (this.state.ProjectID == '') {
            this.setState({ IsValid: true, })
        } else {
            this.wsAddEditTable(this.props.EditID, this.state.ProjectID, this.state.CantrollerID, this.state.Name, this.state.SingularName, this.state.PluralName,)
        }
    }



    wsAddEditTable(ID, ProjectID, ControllerID, Name, SingularName, PluralName) {
        this.setState({ IsLoading: true })
        APIController.AddEditTable(ID, ProjectID, ControllerID, Name, SingularName, PluralName)
            .then((response) => {
                this.setState({ IsLoading: false })
                if (response.error == null) {
                    this.props.handlebind()
                    this.props.handleNotification(true, response.object.message,)
                    this.props.onClose()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
                this.setState({ IsLoading: false })
            })
    }
    wsGetTableByID(ID) {
        this.setState({ IsLoading: true })
        APIController.GetTableByID(ID,)
            .then((response) => {

                if (response.error == null) {
                    this.setState({
                        ProjectID: response.object.data[0].ProjectID,
                        ProjectOption: JSON.parse(response.object.data[0].ProjectName),
                        CantrollerID: response.object.data[0].ControllerID,
                        CantrollerOption: JSON.parse(response.object.data[0].ControllerName),
                        Name: response.object.data[0].Name,
                        SingularName: response.object.data[0].SingularName,
                        PluralName: response.object.data[0].PluralName,
                    })
                    console.log('response.object.data', response.object.data);
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
                this.setState({ IsLoading: false })
            })
    }
    handleNotification(val, msg, type) {
        this.setState({ ShowNotification: val, Message: msg, ToastType: type })
    }

    render() {
        const { ProjectID, ProjectOption, CantrollerOption, CantrollerID, StoredProcedureID, SingularName, PluralName, StoredProcedureIDOption, TableID, TableOption, Name, TypeID, ApiOption, IsValid, IsLoading, IsloadData } = this.state;
        return (
            IsloadData ? <CircularProgress /> :
                <Grid container lg={12} justify='center' >
                    <Grid container justify="space-between">
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <DropDown name={'ProjectID'} value={ProjectID} id={'Project Name'} onChange={this.handleChange} optionText={ProjectOption} helper={'Select Project Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <DropDown name={'CantrollerID'} value={CantrollerID} id={'Cantroller Name'} onChange={this.handleChange} optionText={CantrollerOption} helper={'Select Cantroller Name'} IsValid={IsValid} />
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'Name'} id={'Name'} onChange={this.handleChange} value={Name} helper={'Please Enter Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'SingularName'} id={'Singular Name'} onChange={this.handleChange} value={SingularName} helper={'Please Enter Singular Name'} IsValid={IsValid} />
                        </Grid>
                    </Grid>
                    {/* <Grid container>
                        <DropDown name={'StoredProcedureID'} value={StoredProcedureID} id={'StoredProcedureID'} onChange={this.handleChange} optionText={StoredProcedureIDOption} helper={'Select StoredProcedureID'} IsValid={IsValid} />
                    </Grid> */}

                    <Grid container>
                        <TextField name={'PluralName'} id={'Plural Name'} onChange={this.handleChange} value={PluralName} helper={'Please Enter Plural Name'} IsValid={IsValid} />
                    </Grid>


                    {ApiOption.map((ele, index) => {
                        return (
                            <Grid container xl={12} xs={12} style={{ borderBottom: '2px solid black' }}>

                                <Grid container xl={6} md={6} sm={6} xs={6}>
                                    <CheckBox color='green' checked={ele.ID} onChange={(event) => this.handleChange('', event, ele.Name)} label={ele.Name} />
                                </Grid>

                            </Grid>
                        )
                    })}
                    <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <DialogActions>
                            <CommonButton IsLoading={IsLoading} onClick={() => this.handleValidate()} color="primary">{'Save'}</CommonButton>
                            <CommonButton onClick={() => this.props.onClose()} color="secondary">{'Close'}</CommonButton>
                        </DialogActions>
                    </Grid>
                </Grid>
        );
    }
}
export default MyApiForm;
