import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CommonButton from "../Button/CommonButton";
import { Typography } from '@material-ui/core';
import './commonModal.css'

function CommonModal(props) {
    const { Title, ActionText, onClose, maxWidth, IsLoading, onSave, IsScroll, children, IsDeletePopup, OrderNO } = props;
    const handleClose = () => {
        onClose(false)
    };

    return (
        <div >
            <Dialog
                open={true}
                maxWidth={maxWidth}
                onClose={props.isOuterClick == undefined ? handleClose : props.isOuterClick}

                scroll={IsScroll != undefined ? 'body' : 'paper'}
                aria-labelledby="scroll-dialog-title">
                <div style={{ display: 'flex', justifyContent: 'space-between', overflow: "hidden" }}>
                    <DialogTitle style={{ marginBottom: '0px',color:'#0d5a2e',fontWeight:900 }} id="scroll-dialog-title">{Title}</DialogTitle>
                    {OrderNO != "" && OrderNO != undefined && OrderNO != null && <DialogTitle style={{ marginBottom: '0px', }} >{OrderNO}</DialogTitle>}
                </div>
                <DialogContent style={{ overflow: 'hidden' }} >
                    <DialogContentText>
                        {IsDeletePopup && <Typography color='textPrimary' style={{ fontSize: 20, fontWeight: 'bold' }}>Are you sure you want to {Title}?</Typography>}
                        {children}
                    </DialogContentText>
                </DialogContent>
                {IsDeletePopup &&

                    <DialogActions>
                        <CommonButton IsLoading={IsLoading} onClick={onSave} color="primary">
                            {ActionText}
                        </CommonButton>
                        <CommonButton onClick={handleClose} color="secondary">
                            {'Close'}
                        </CommonButton>
                    </DialogActions>
                }

            </Dialog>

        </div>
    );

}

export default CommonModal;