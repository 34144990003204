
import React, { Fragment } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
    demo: {
        height: 'auto',
    },
    divider: {
        display: 'block',
        margin: `${theme.spacing(3)}px 0`,
    },
    field: {
        margin: `${theme.spacing(3)}px 5px`,
    },
    rootgreen: {
        color:  theme.palette.primary.main,
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    rootred: {
        color:  theme.palette.primary.CustomTableHeader,
        '&$checked': {
            color:  theme.palette.primary.CustomTableHeader,
        },
    },
    checked: {},
    size: {
        width: 40,
        height: 40,
    },
    sizeIcon: {
        fontSize: 20,
    },
    formControl: {
        // width: '100%'
        color: '#000',
        '& span': {
            padding: '3px'
        }
    },
});
function Checkboxes(props) {
    const { classes, name, color, value, id, label, icon, checkedIcon, onChange, checked,disabled } = props;
    return (
        <Fragment>
            <FormControl component="fieldset" required className={classes.formControl}>
                <FormControlLabel
                    style={{ marginRight: '-5px', marginLeft: '0px', }}
                    control={(
                        <Checkbox
                            classes={color != undefined && color === 'green' ? { root: classes.rootgreen } : color != undefined && color === 'blue' ? { root: classes.rootred } : ''}
                            name={name}
                            id={id}
                            disabled={disabled}
                            className={classes.CardContentStyle}                            icon={icon != undefined ? <FilterNoneIcon style={{ fontSize: 20 }} /> : <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                            checkedIcon={checkedIcon != undefined ? <LibraryAddCheckIcon style={{ fontSize: 20 }} /> : <CheckBoxIcon style={{ fontSize: 20 }} />}
                            checked={checked}
                            onChange={onChange}
                            value={value}
                            color={color}
                        />
                    )}
                    label={label}
                />
            </FormControl>
        </Fragment>
    );

}

Checkboxes.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Checkboxes);