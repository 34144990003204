import green from '@material-ui/core/colors/green';

import lightBlue from '@material-ui/core/colors/lightBlue';
const CommonStyle = theme => ({
    table: {
        responsiveStacked: {
            maxHeight: 'none',
            overflowX: 'auto'
        },
        '& > div': {
            overflow: 'auto',
            overflowX: 'auto',
            fontFamily: ['Nunito', 'sans-serif'].join(','),
        },
        '& td': {
            wordBreak: 'keep-all',
            fontFamily: ['Nunito', 'sans-serif'].join(','),
            borderColor: theme.palette.primary.main,
            fontSize: 16,
            padding: '0px 5px',
        },
        '& th': {
            borderBottom: '0px solid #000',
            fontFamily: ['Nunito', 'sans-serif'].join(','),
            fontSize: '16px',
            borderColor: theme.palette.primary.main,
            paddingLeft: '0px',
            '&:first-child': {
                padding: '5px',
                // minWidth: '150px',
                // textAlign: 'center'
            },
            '&:nth-child(2)': {
                minWidth: '170px',
            },
            '&:nth-child(3)': {
                minWidth: '170px',
            },
            '&:nth-child(4)': {
                minWidth: '170px',
            },
            '&:nth-child(5)': {
                minWidth: '158px',
            },
            '&:nth-child(6)': {
                minWidth: '130px',
            },
            '&:nth-child(7)': {
                minWidth: '125px',
            },
            '&:nth-child(8)': {
                minWidth: '133px',
            },
            '&:nth-child(9)': {
                minWidth: '125px',
            },
            '&:nth-child(10)': {
                minWidth: '125px',
            },
            '&:nth-child(11)': {
                minWidth: '125px',
            },
            '&:nth-last-child(2)': {
                minWidth: '125px',
                '& span': {
                    justifyContent: 'center'
                },
            },
            '&:last-child': {
                minWidth: '125px',
                justifyContent: 'center',
            }
        },
        [theme.breakpoints.down('md')]: {
            '& td': {
                height: 60,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        '& td button': {
            padding: '7px'
        },
        '& td p': {
            fontSize: '0.9rem',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
            maxWidth: '190px',
            maxWidth: '230px'
        },

    },
    AddEditIcon: {
        fontSize: 18,
    },
    ActiveSwithchStyle: {
        // fontSize: 18,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    errorMsg: {
        fontFamily: ['Nunito', 'sans-serif'].join(','),
        fontWeight: 400,
        position: 'absolute',
        top: 10,
        right: 10,
        whiteSpace: 'nowrap',
        overflow: 'ellipsis',
        color: theme.palette.primary.Error,
        fontSize: 15,

    },

    formControl: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        margin: theme.spacing(1),
    },
    textField: {
        borderRadius: 20
    },

    SearchBarContent: {
        padding: '0',
        backgroundColor: theme.palette.primary.Searchbackground,
        // marginBottom:'50px'
    },
    SearchInput: {
        width: '100%',
        fontFamily: ['Nunito', 'sans-serif'].join(','),
        fontWeight: 700,
        padding: '4px',
        fontSize: 14,
        border: 0,
    },
    SearchDrop: {
        padding: '5px',
        fontSize: 12,
        cursor: 'pointer',
        fontFamily: ['Nunito', 'sans-serif'].join(','),
        fontWeight: 700,
        width: '100%',
        border: 0,
    },
    MuIChipStatuColor: {
        fontFamily: ['Nunito', 'sans-serif'].join(','),
        fontWeight: 700,
        fontSize: 12,
        // color: theme.palette.primary.ChipActiveText,
        // backgroundColor: theme.palette.primary.ChipActivebackground,
        borderRadius: '1px',
        // width: '47px',
        height: '22px',
    },
    MuIChipTrueColor: {
        fontFamily: ['Nunito', 'sans-serif'].join(','),
        fontWeight: 700,
        fontSize: 12,
        color: theme.palette.primary.ChipActiveText,
        backgroundColor: theme.palette.primary.ChipActivebackground,
        borderRadius: '1px',
        width: '47px',
        height: '22px',
    },
    MuIChipFalseColor: {
        fontFamily: ['Nunito', 'sans-serif'].join(','),
        fontWeight: 700,
        fontSize: 12,
        borderRadius: '1px',
        color: theme.palette.secondary.ChipInActiveText,
        backgroundColor: theme.palette.secondary.ChipInActivebackground,
        width: '47px',
        height: '22px',
    },
    CardStyle: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        opacity: 0.8,
        '&:hover': {
            color: '#fff',
            opacity: 1,
            boxShadow: theme.shadows[10],
        },
    }, AddColorEditIcon: {
        fontSize: 20,
        color: theme.palette.primary.main,

        // bottom:2,
        // marginRight:20,
        cursor:'pointer',
    },
    CartTextStyle: {
        fontWeight: 600,
        fontSize: 18,
    },
    NodataText: {
        textAlign: 'center',
        fontFamily: ['Nunito', 'sans-serif'].join(','),
        fontWeight: 700,
        padding: '15px',
        fontSize: 18,
    },
    pageTitle: {
        // padding: theme.spacing(1),
        // paddingBottom: theme.spacing(1),
        // paddingTop: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            alignItems: 'center',
        },
        '& h4': {
            fontWeight: 700,
            fontSize: 24,
            //   paddingLeft: -10,
            paddingRight: theme.spacing(1),
            textTransform: 'capitalize',
            color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.dark,
            [theme.breakpoints.down('md')]: {
                marginBottom: theme.spacing(3)
            }
        },
    },

    MUIDataTablePagination: {
        toolbar: {
            padding: 0,
            '& > p:nth-child(2)': {
                '@media (max-width: 400px)': {
                    display: 'none'
                }
            },
        },
        root: {
            padding: 0
        }
    },

})
export default CommonStyle