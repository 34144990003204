// import * as APICaller from '../APIs/APICaller';
import { API, HTTPMethod } from "../APIs/API";
import { URLs } from "../APIs/URLs";

export function APIControllerError(code, message) {
	this.code = code
	this.message = message
}

export function APIControllerResponse(object, error) {
	this.object = object
	this.error = error
}




export function GetAccessToken(username, password) {
	// let data = JSON.parse(values)
	var details = {
		username: username,
		password: password,
		DeviceID: '1111111111-1111111111',
		DeviceTypeID: '1',
		grant_type: "password",
	};
	var formBody = [];
	for (var property in details) {
		var encodedKey = encodeURIComponent(property);
		var encodedValue = encodeURIComponent(details[property]);
		formBody.push(encodedKey + "=" + encodedValue);
	}
	formBody = formBody.join("&");

	let url = URLs.base + URLs.Gettoken;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, formBody)

			.then((response) => {
				if (response && response.StatusCode === 401) {
					let error = new APIControllerError(response.StatusCode, response.Message)
					resolve(new APIControllerResponse(null, error))
				} else if (response && response.access_token) {
					resolve(new APIControllerResponse(response, null))
					localStorage.setItem("Access_Token", response.access_token);
				} else if (response.error_description != '') {
					// ToastsStore.warning(response.error_description)
					let error = new APIControllerError(response)
					resolve(new APIControllerResponse(null, error))
				}
			})
			.catch((error) => {
				resolve(new APIControllerResponse(null, error))
			});
	});
}

export function RegisterBrowserNotificationToken(Token) {
	let params = JSON.stringify({ 'Token': Token })
	let url = URLs.base + URLs.RegisterBrowserNotificationToken;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error));
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null));
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error));
		})
	})
};

export function VerifyOTP(OTP) {
	let params = JSON.stringify({ 'OTP': OTP })
	let url = URLs.base + URLs.VerifyOTP;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function ResendOTP() {
	let params = JSON.stringify({})
	let url = URLs.base + URLs.ResendOTP;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function SetPassword(AccessCode, Password) {
	let params = JSON.stringify({
		'AccessCode': AccessCode,
		'Password': Password
	})
	let url = URLs.base + URLs.SetPassword;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function VerifyToken() {
	let params = JSON.stringify({})
	let url = URLs.base + URLs.VerifyToken;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function SendNotification(JSONData, Subject, Message, SendSMS, SendEmail, SendPushNotification) {
	let params = JSON.stringify({
		"IDs": JSONData,
		"Subject": Subject,
		"Message": Message,
		"SendSMS": SendSMS,
		"SendEmail": SendEmail,
		"SendPushNotification": SendPushNotification
	})
	let url = URLs.base + URLs.SendNotification;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}



export function GetNav() {
	let params = JSON.stringify({})
	let url = URLs.base + "API/ACS/GetNav";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
// Project Api

export function GetProjects(Name, ConnectionString,  IsDefault,IsActive,) {
	let params = JSON.stringify({
		'Name': Name,
		'ConnectionString': ConnectionString,
		'IsActive': IsActive == "" ? null : IsActive,
		'IsDefault': IsDefault == "" ? null : IsDefault,
	})
	let url = URLs.base + "API/Master/GetProjects";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function DeleteProject(ID) {
	let params = JSON.stringify({ 'ID': ID })
	let url = URLs.base + "API/Master/DeleteProject";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function AddEditProject(ID, Name, ConnectionString, IsActive, IsDefault) {
	let params = JSON.stringify({
		'ID': ID,
		'Name': Name,
		'ConnectionString': ConnectionString,
		'IsActive': IsActive == null ? false : IsActive,
		'IsDefault': IsDefault == null ? false : IsDefault,

	})
	let url = URLs.base + "API/Master/AddEditProject";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetProjectByID(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + "API/Master/GetProjectByID";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {

			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

// Controller Api

export function GetControllers(ProjectName, Name, IsActive) {
	let params = JSON.stringify({
		"ProjectName": ProjectName,
		'Name': Name,
		IsActive: IsActive == "" ? null : IsActive,
	})
	let url = URLs.base + "API/Master/GetControllers";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function GetControllerForDropdown() {
	let params = JSON.stringify({

	})
	let url = URLs.base + "API/Master/GetControllerForDropdown";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

// Table Api 


export function GetTables(ProjectName, ControllerName,  Name, SingularName, PluralName, CurrentPage, RecordPerPage, SortExpression, SortDirection) {
	let params = JSON.stringify({

		"ProjectName": ProjectName,
		"ControllerName": ControllerName,
		"Name": Name,
		"SingularName": SingularName,
		"PluralName": PluralName,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
		"SortExpression": SortExpression,
		"SortDirection": SortDirection

	})
	let url = URLs.base + "API/Master/GetTables";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {

			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function DeleteTable(ID) {
	let params = JSON.stringify({

		"ID": ID

	})
	let url = URLs.base + "API/Master/DeleteTable";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {

			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}














export function GetControllerByID(ID) {
	let params = JSON.stringify({
		'ID': ID


	})
	let url = URLs.base + "API/Master/GetControllerByID";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {

			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function AddEditController(ID, ProjectID, Name, IsActive,) {
	let params = JSON.stringify({
		"ProjectID": ProjectID,
		"Name": Name,
		'IsActive': IsActive == 0 ? false : true,
		'ID': ID
	})
	let url = URLs.base + "API/Master/AddEditController";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function DeleteController(ID) {
	let params = JSON.stringify({ 'ID': ID })
	let url = URLs.base + "API/Master/DeleteController";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function GetMyAPIForDropdown(ProjectID,TableID) {
	let params = JSON.stringify({
		"ProjectID":ProjectID,
		"TableID":TableID,
	})
	let url = URLs.base + "API/Master/GetMyAPIForDropdown";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
// My Api

export function GetMyAPIs(ProjectName, TableName, TypeID, Name,OperationID,ResponseObject,CurrentPage, RecordPerPage, SortExpression, SortDirection) {
	let params = JSON.stringify({
		"ProjectName": ProjectName,
		"TableName": TableName,
		"TypeID": TypeID,
		'Name': Name,
		'OperationID': OperationID,
		'ResponseObject': ResponseObject,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
		"SortExpression": SortExpression,
		"SortDirection": SortDirection
	})
	let url = URLs.base + "API/Master/GetMyAPIs";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function GetMyAPIByID(ID) {
	let params = JSON.stringify({
		'ID': ID
	})
	let url = URLs.base + "API/Master/GetMyAPIByID";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {

			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function AddEditMyAPI(ID, ProjectID, TableID, Name, TypeID,OperationID,Response, json) {
	console.log('Name', Name);
	console.log('TableID', TableID);
	console.log('ProjectID', ProjectID);
	let params = JSON.stringify({
		"JSON": json,
		"ProjectID": ProjectID,
		"OperationID": OperationID,
		"Response": Response,
		"TableID": TableID,
		"Name": Name,
		"TypeID": TypeID,
		'ID': ID
	})
	let url = URLs.base + "API/Master/AddEditMyAPI";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function DeleteMyAPI(ID,) {
	let params = JSON.stringify({

		'ID': ID
	})
	let url = URLs.base + "API/Master/DeleteMyAPI";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetProjectForDropdown() {
	let params = JSON.stringify({
	})
	let url = URLs.base + "API/Master/GetProjectForDropdown";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function GetTableForDropdown(ID) {
	let params = JSON.stringify({
		'ProjectID':ID
	})
	let url = URLs.base + "API/Master/GetTableForDropdown";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function GetAllTables() {
	let params = JSON.stringify({

	})
	let url = URLs.base + "API/DB/GetAllTables";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function AddEditTable(ID, ProjectID, ControllerID, Name, SingularName, PluralName) {
	let params = JSON.stringify({
		'ID': ID,
		"ProjectID": ProjectID,
		"ControllerID": ControllerID,
		"Name": Name,
		"SingularName": SingularName,
		"PluralName": PluralName
	})
	let url = URLs.base + "API/Master/AddEditTable";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function GetTableByID(ID,) {
	let params = JSON.stringify({
		'ID': ID,

	})
	let url = URLs.base + "API/Master/GetTableByID";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
// export function GetTableForDropdown() {
// 	let params = JSON.stringify({
// 	})
// 	let url = URLs.base + "API/Master/GetTableForDropdown";

// 	return new Promise((resolve, reject) => {
// 		API.shared.request(HTTPMethod.post, url, params).then((response) => {
// 			if (response && response.StatusCode === 401) {
// 				let error = new APIControllerError(response.StatusCode, response.Message)
// 				resolve(new APIControllerResponse(null, error))
// 			}
// 				else if (response ) {
// 				resolve(new APIControllerResponse(response, null))
// 			}
// 		}).catch((error) => {
// 			resolve(new APIControllerResponse(null, error))
// 		})
// 	})
// }




// GetConfiguredTables Api

export function GetConfiguredTables(ProjectID) {
	let params = JSON.stringify({
		"ProjectID": ProjectID
	})
	let url = URLs.base + "API/DB/GetConfiguredTables";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function SaveConfiguredTables(ProjectID, TableID, Fields) {
	let params = JSON.stringify({
		"ProjectID": ProjectID,
		"Fields": Fields,
		"TableID": TableID
	})
	let url = URLs.base + "API/DB/SaveConfiguredTables";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}




export function GetConfiguredFields(ProjectID, TableID, TableName,TypeID,APIID) {
	let params = JSON.stringify({
		"ProjectID": ProjectID,
		"TableID": TableID,
		"TableName": TableName,
		"TypeID": TypeID,
		"APIID": APIID,
	})
	let url = URLs.base + "API/DB/GetConfiguredFields";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function GetFieldForDropdown(ProjectID,TableID) {
	let params = JSON.stringify({
		"ProjectID":ProjectID,
		"TableID":TableID
	})
	let url = URLs.base + "API/Master/GetFieldForDropdown";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function SaveConfiguredFields(ProjectID, TableID, Fields) {
	let params = JSON.stringify({
		"ProjectID": ProjectID,
		"TableID": TableID,
		"Fields": Fields,
	})
	let url = URLs.base + "API/DB/SaveConfiguredFields";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}



export function GenerateStoredProcedure(Name) {
	let params = JSON.stringify({
		"Name": Name,
	})
	let url = URLs.base + "API/Action/GenerateStoredProcedure";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}



// Screens

export function GetScreens(ProjectName, TypeID,Name,TypeName,APIName) {
	let params = JSON.stringify({
		"ProjectName": ProjectName,
		"TypeID": TypeID,
		"Name": Name,
		"TypeName": TypeName,
		"APIName": APIName,
	})
	let url = URLs.base + "API/Master/GetScreens";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function AddEditScreen(ID, ProjectName, TableID, Name, TypeID,ApiID, Json) {
	let params = JSON.stringify({
		"ID": ID,
		"TypeID": TypeID,
		"ProjectID": ProjectName,
		"TableID": TableID,
		"Name": Name,
		"JSON": Json,
		"APIID": ApiID,
	})
	let url = URLs.base + "API/Master/AddEditScreen";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function DeleteScreen(ID,) {
	let params = JSON.stringify({
		"ID": ID,

	})
	let url = URLs.base + "API/Master/DeleteScreen";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function GetScreenByID(ID,) {
	let params = JSON.stringify({
		"ID": ID,
	})
	let url = URLs.base + "API/Master/GetScreenByID";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

// Table Api 
// export function GetTables(ProjectName, TableName, ControllerName, StoredProcedureName, SingularName, PluralName,) {
// 	let params = JSON.stringify({
// 		"ProjectName": ProjectName,
// 		'TableName': TableName,
// 		'ControllerName': ControllerName,
// 		'StoredProcedureName': StoredProcedureName,
// 		'SingularName': SingularName,
// 		'PluralName': PluralName,
// 	})
// 	let url = URLs.base + "API/Master/GetTables";

// 	return new Promise((resolve, reject) => {
// 		API.shared.request(HTTPMethod.post, url, params).then((response) => {
// 			if (response && response.StatusCode === 401) {
// 				let error = new APIControllerError(response.StatusCode, response.Message)
// 				resolve(new APIControllerResponse(null, error))
// 			}
// 				else if (response ) {
// 				resolve(new APIControllerResponse(response, null))
// 			}
// 		}).catch((error) => {
// 			resolve(new APIControllerResponse(null, error))
// 		})
// 	})
// }

// Store Producered

export function GetGenerateStoreProducereds(Name) {
	let params = JSON.stringify({
		'Name': Name,
	})
	let url = URLs.base + "/API/DB/GenerateStoredProcedure";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

// get fileds 
export function GetFields(ProjectName, TableName, Name, Type, ParameterName, FriendlyName, SequenceNo, CurrentPage, RecordPerPage, SortExpression, SortDirection) {
	let params = JSON.stringify({
		'ProjectName': ProjectName,
		'TableName': TableName,
		'Name': Name,
		'Type': Type,
		'ParameterName': ParameterName,
		'FriendlyName': FriendlyName,
		'SequenceNo': SequenceNo == '' ? null : SequenceNo,
		"CurrentPage": CurrentPage,
		"RecordPerPage": RecordPerPage,
		"SortExpression": SortExpression,
		"SortDirection": SortDirection

	})
	let url = URLs.base + "API/Master/GetFields";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

// Delete Fields

export function DeleteField(ID,) {
	let params = JSON.stringify({
		"ID": ID,

	})
	let url = URLs.base + "API/Master/DeleteField";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

// Add/edit Fields

export function AddEditField(ID, ProjectID, TableID, Name, Type, ParameterName, FriendlyName, SequenceNo) {
	let params = JSON.stringify({
		'ID': ID,
		'ProjectID': ProjectID,
		'TableID': TableID,
		'Name': Name,
		'Type': Type,
		'ParameterName': ParameterName,
		'FriendlyName': FriendlyName,
		'SequenceNo': SequenceNo,

	})
	let url = URLs.base + "API/Master/AddEditField";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))

			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
// GetFieldByID 
export function GetFieldByID(ID,) {
	let params = JSON.stringify({
		"ID": ID,
	})
	let url = URLs.base + "API/Master/GetFieldByID";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}





// screens
export function GenerateAddEditScreen(ID,) {
	let params = JSON.stringify({
		"ScreenID": ID,
	})
	let url = URLs.base + "API/Action/GenerateAddEditScreen";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function GenerateListingScreen(ID,) {
	let params = JSON.stringify({
		"ScreenID": ID,
	})
	let url = URLs.base + "API/Action/GenerateListingScreen";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

// GenerateAPI

export function GenerateAPI(ID,) {
	let params = JSON.stringify({
		"ID": ID,
	})
	let url = URLs.base + "API/Action/GenerateAPI";

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
				else if (response ) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}




// paras end