import { CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import * as APIController from '../../APIs/APIController.js';
import CommonButton from "../../Camponets/Button/CommonButton.js";
import TextField from "../../Camponets/TextField/TextField.js";
import CommonSwitch from "../../Camponets/Switch/CommonSwitch.js";

class ProjectForm extends React.Component {
    state = {
        ID: '',
        Name: '',
        ConnectionString: '',
        EditID: '0',
        IsActive: true,
        IsDefault: false,
        ProjectOption: [],
        IsValid: false,
        IsLoading: false,
        IsloadData: false
    }

    componentDidMount() {
        this.props.EditID != 0 && this.wsGetProjectByID(this.props.EditID);
    }


    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    handleValidate() {
        if (this.state.Name == '') {
            this.setState({ IsValid: true, })
        } else {
            this.wsAddEditProject(this.props.EditID, this.state.Name, this.state.ConnectionString, this.state.IsActive, this.state.IsDefault,)
        }
    }


    wsGetProjectByID(ID) {
        this.setState({ IsloadData: true })
        APIController.GetProjectByID(ID)
            .then((response) => {
                if (response.error == null) {
                    this.setState({
                        Name: response.object.data[0].Name,
                        ConnectionString: response.object.data[0].ConnectionString,
                        IsActive: response.object.data[0].IsActive,
                        IsDefault: response.object.data[0].IsDefault,

                    })

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
                this.setState({ IsloadData: false })
            })
    }

    wsAddEditProject(ID, Name, ConnectionString, IsActive, IsDefault,) {
        this.setState({ IsLoading: true })
        APIController.AddEditProject(ID, Name, ConnectionString, IsActive, IsDefault,)
            .then((response) => {
                this.setState({ IsLoading: false })
                if (response.error == null) {
                    this.props.handlebind()
                    this.props.handleNotification(true, response.object.message,)
                    this.props.onClose()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    handleNotification(val, msg, type) {
        this.setState({ ShowNotification: val, Message: msg, ToastType: type })
    }

    render() {
        const { Name, ConnectionString, IsActive, IsDefault, IsValid, IsLoading, IsloadData } = this.state;
        return (
            IsloadData ? <CircularProgress /> :
                <Grid container lg={12} justify='center' >
                    <Grid container justify="space-between">
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'Name'} id={'Name'} onChange={this.handleChange} value={Name} helper={'Please Enter Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'ConnectionString'} id={'Connection String'} onChange={this.handleChange} value={ConnectionString} helper={'Please Connection String'} IsValid={IsValid} />
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <CommonSwitch name={'IsActive'} value={IsActive} id={'IsActive'} onChange={(e) => this.setState({ IsActive: e.target.checked })} label={'Is Active?'} checked={IsActive} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <CommonSwitch name={'IsDefault'} value={IsDefault} id={'IsDefault'} onChange={(e) => this.setState({ IsDefault: e.target.checked })} label={'Is Default?'} checked={IsDefault} />
                        </Grid>
                    </Grid>



                    <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <DialogActions>
                            <CommonButton IsLoading={IsLoading} onClick={() => this.handleValidate()} color="primary">{'Save'}</CommonButton>
                            <CommonButton onClick={() => this.props.onClose()} color="secondary">{'Close'}</CommonButton>
                        </DialogActions>
                    </Grid>
                </Grid>

        );
    }
}
export default ProjectForm;
