import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CommonStyle from "../../Camponets/config/CommonStyle";

class SelectVariant extends React.Component {
    state = {
        labelWidth: 0,
    };

    componentDidMount() {
        this.setState({ // eslint-disable-line
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth, // eslint-disable-line
        });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { classes, onChange, name, value, id, helper, optionText, IsValid, values, NotName, disabled } = this.props;
        const { labelWidth } = this.state;
        return (

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                    error={value == '' && IsValid == true ? true : false}

                    ref={ref => {
                        this.InputLabelRef = ref;
                    }} >
                    {value == '' && IsValid == true ? null : id}
                </InputLabel>
                <Select
                    native
                    disabled={disabled}
                    value={value}
                    error={value == '' && IsValid == true ? true : false}
                    name={name}
                    
                    onChange={onChange}
                    input={(
                        <OutlinedInput
                            name="age"
                            labelWidth={labelWidth}
                            id="outlined-age-native-simple"
                        />
                    )}  >
                    <option value="" />
                    {NotName ? optionText != null && optionText != undefined && optionText.map((item) => {
                        return (<option selected={item.ID == values ? item.ID : item.FullName} value={item.ID}>{item.FullName}</option>)
                    }) :
                        optionText != null && optionText != undefined && optionText.map((item) => {
                            return (<option selected={item.ID == values ? item.ID : item.Name} value={item.ID}>{item.Name}</option>)
                        })}
                </Select>
                {helper && value == '' && IsValid == true && <FormHelperText className={classes.errorMsg} id="name-helper-text">{helper}</FormHelperText>}
            </FormControl>



        );
    }
}

SelectVariant.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(CommonStyle)(SelectVariant);