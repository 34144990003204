// import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
// import { withStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CancelIcon from '@material-ui/icons/Cancel';
// import Save from '@material-ui/icons/Save';
// import Delete from '@material-ui/icons/Delete';

// const styles = theme => ({

//     // root: {
//     //     display: 'flex',
//     //     alignItems: 'center',
//     // },
//     // button: {
//     //     margin: theme.spacing(1),
//     // },
//     wrapper: {
//         margin: theme.spacing(1),
//         position: 'relative',
//     },
//     buttonSuccess: {
//         // backgroundColor: theme.palette.primary.main,
//         // '&:hover': {
//         //     backgroundColor: theme.palette.secondary.main,
//         // },
//     },

//     buttonProgress: {
//         color: theme.palette.primary.main,
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         marginTop: -12,
//         marginLeft: -12,
//     },

// });

// function CommonButton(props) {
//     const { classes, name, IsValid, color, id, ButtonText, IsLoading, disabled, onClick, children, type } = props;
//     return (
//         <Fragment>
//             {/* <Button variant="contained" type={type != undefined && 'submit' } name={name} id={id} color={color != undefined ? color : 'primary'} onClick={onClick} className={classes.button} >
//                 {ButtonText}
//                 {children}
//             </Button> */}
//             <div className={classes.wrapper}>
//                 <Button
//                     type={type != undefined && 'submit'} name={name} id={id}
//                     variant="contained"
//                     color={color != undefined ? color : 'primary'}
//                     onClick={onClick}
//                     className={classes.buttonSuccess}
//                     disabled={IsLoading || IsValid || disabled} >
//                     {ButtonText}
//                     {children == 'Close' ? <CancelIcon style={{ fontSize: 20, marginRight: 5 }} /> : children == 'Save' ? <Save style={{ fontSize: 20, marginRight: 5 }} /> : children === 'Delete' ? <Delete style={{ fontSize: 20, marginRight: 5 }} /> : children === 'Change' ? <Save style={{ fontSize: 20, marginRight: 5 }} /> : children === 'Update' ? <Save style={{ fontSize: 20, marginRight: 5 }} /> : null}
//                     {children}
//                 </Button>
//                 {IsLoading && IsLoading != undefined && <CircularProgress size={24} className={classes.buttonProgress} />}
//             </div>
//         </Fragment>
//     );
// }

// CommonButton.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

// export default withStyles(styles)(CommonButton);




import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';

const styles = theme => ({

    // root: {
    //     display: 'flex',
    //     alignItems: 'center',
    // },
    // button: {
    //     margin: theme.spacing(1),
    // },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        // backgroundColor: theme.palette.primary.main,
        // '&:hover': {
        //     backgroundColor: theme.palette.secondary.main,
        // },
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

});

function CommonButton(props) {
    const { classes, name, color, id, ButtonText, IsLoading, disabled, onClick, children, type } = props;
    return (
        <Fragment>
            {/* <Button variant="contained" type={type != undefined && 'submit' } name={name} id={id} color={color != undefined ? color : 'primary'} onClick={onClick} className={classes.button} >
                {ButtonText}
                {children}
            </Button> */}
            <div className={classes.wrapper}>
                <Button
                    type={type != undefined && 'submit'} name={name} id={id}
                    variant="contained"
                    color={color != undefined ? color : 'primary'}
                    onClick={onClick}
                    className={classes.buttonSuccess}
                    disabled={IsLoading || disabled} >
                    {ButtonText}
                    {children == 'Close' ? <CancelIcon style={{ fontSize: 20, marginRight: 5 }} /> : children == 'Save' ? <Save style={{ fontSize: 20, marginRight: 5 }} /> : children === 'Delete' ? <Delete style={{ fontSize: 20, marginRight: 5 }} /> : children === 'Change' ? <Save style={{ fontSize: 20, marginRight: 5 }} /> : children === 'Update' ? <Save style={{ fontSize: 20, marginRight: 5 }} /> : null}
                    {children}
                </Button>
                {IsLoading && IsLoading != undefined && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </Fragment>
    );
}

CommonButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CommonButton);