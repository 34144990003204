
import React, { useEffect, useState } from 'react';
import * as APIController from '../../APIs/APIController';
import DataGrid from '../../Camponets/DataGrid/DataGrid';
import CommonModal from "../../Camponets/Modal/CommonModal";
import Notification from '../../Camponets/Notification/Notification';
import CommonLoader from "../../Camponets/CommonLoader/CommonLoader";
import { AppConstant, TableColor } from "../../Camponets/AppConstant";
import MyApiForm from './MyApiForm';

const tableStyle = [
    {
        headerName: 'Action', actionType: 1, width: "80px", position: 'center', actionButton: [{ id: 2, name: 'Edit', icon: "", color: "error", size: "18px", type: 'icon' }, { id: 3, name: 'Delete', icon: "", color: "success", size: "20px", type: "icon" },
        { id: 4, name: 'View', icon: "", color: "success", size: "20px", type: "icon" },
        { id: 5, name: 'Apicontroller', icon: "", color: "success", size: "20px", type: "icon" },
        { id: 6, name: 'Caller', icon: "", color: "success", size: "20px", type: "icon" },
        { id: 7, name: 'APIParameters', icon: "", color: "success", size: "20px", type: "icon" },
        { id: 8, name: 'API', icon: "", color: "success", size: "20px", type: "icon" },
        { id: 9, name: 'Model', icon: "", color: "success", size: "20px", type: "icon" },
        ]
    },
    { fieldName: 'ProjectName', headerName: 'Project Name', actionType: 2 },
    { fieldName: 'TableName', headerName: 'Table Name', actionType: 2 },
    { fieldName: 'TypeID', headerName: 'Type', actionType: 2 },
    { fieldName: 'Name', headerName: 'APIName', actionType: 2 },
    { fieldName: 'OperationID', headerName: 'Operation ID', actionType: 2 },
    { fieldName: 'ResponseObject', headerName: 'Response Text', actionType: 2 },

]

const button = [
    { id: 4, color: "", name: "Add", type: 'icon' },
    { id: 7, color: "", name: "View", type: 'icon' },
];

export default function MyAPIsList() {
    const [IsFilter, setIsFilter] = useState(true);
    const [tableHeader, setTableHeader] = useState("My APIs")
    const [field, setField] = useState()
    const [gridData, setGridData] = useState([]);
    const [chekedAll, setChekedAll] = useState(false);
    const [checked, setChecked] = useState([]);
    const [showSelectAllBox, setShowSelectAllBox] = useState(false)
    const [loading, setLoading] = useState(false);
    const [sorting, setSorting] = useState({ field: 'name', ascending: false });
    const [searchingInput, setSearchingInput] = useState({ field: "", searching: false });
    const [shortingIcon, setSortingIcon] = useState(false);
    const [CurrentPage, setCurrentPage] = useState(0);
    const [RecordPerPage, setRecordPerPage] = useState(AppConstant.DefaultRecordsPerPage);
    const [SortDirection, setSortDirection] = useState(AppConstant.DefaultSort);
    const [SortExpression, setSortExpression] = useState("Name");
    const [MaxRows, setMaxRows] = useState(0);
    const [gotoPage, setGotoPage] = useState();
    const [IsloadList, setIsloadList] = useState(true);
    const [ShowNotification, setShowNotification] = useState(false);
    const [ToastType, setToastType] = useState('');
    const [Message, setMessage] = useState('');
    const [EditID, setEditID] = useState(0);
    const [InitialState, setInitialState] = useState({
        PopupTitle: "", PopupActionText: "", open: "", Action: "", EditID: "", loading: false
    });

    //  Get Required Data for the Grid
    const WsGetMyAPIs = (ProjectName, TableName, TypeID, Name, OperationID, ResponseObject, CurrentPage, RecordPerPage, SortExpression, SortDirection) => {
        APIController.GetMyAPIs(ProjectName, TableName, TypeID, Name, OperationID, ResponseObject, CurrentPage, RecordPerPage, SortExpression, SortDirection)
            .then((response) => {
                if (response.error == null) {
                    let finaldata = response.object.data;
                    setGridData(finaldata);
                    setMaxRows(response.object.totalRecords);
                    setIsloadList(false);
                };
            });
    };

    const fieldName = () => {
        const tableField = tableStyle.filter((ele, ind) => {
            return ele.actionType === 2 || ele.actionType === 3;
        });
        const setTableField = tableField.map((ele, ind) => {
            const { fieldName } = ele;
            return fieldName;
        });
        const obj = {};
        setTableField.forEach((element, index) => {
            obj[element] = "";
        });
        setField(obj);
        const apiArry = Object.values(obj);
        return apiArry.flat(1);
    };

    const apiParamWithFilter = (field, CurrentPage, RecordPerPage, SortExpression, SortDirection) => {
        const apiArry = Object.values(field);
        const margeArry = apiArry.flat(1);
        WsGetMyAPIs(...margeArry, CurrentPage, RecordPerPage, SortExpression, SortDirection);
    };

    useEffect(() => {
        let apiPerameter = fieldName();
        WsGetMyAPIs(...apiPerameter, CurrentPage, RecordPerPage, SortExpression, SortDirection);
        setLoading(false);
    }, []);

    // Show Button for Adding the Data
    const handleTopBtnClick = ((e, btnId) => {
        if (btnId == 4) {
            handleOpen('Add', 0);
        } else if (btnId == 7) {
            wsGenerateAPI(0)
        }
    });

    // Select All Checkboxes
    const hendleCheckedAll = (e) => {
        setChekedAll(e.target.checked);
        if (e.target.checked) {
            const allRows = gridData.map((c) => c);
            setChecked(allRows);
        } else {
            setChecked([]);
        };
    };

    // Select the Checkbox
    const handleCheckBox = (e, ele, eleId, buttonId) => {
        setShowSelectAllBox(true);
        if (e.target.checked) {
            setChecked([...checked, ele]);
        } else {
            setChecked(checked.filter((item) => item.ID != ele.ID));
        };
    };

    // Icons for Action
    const handleActionBtnClick = ((ele, eleId, btnId) => {
        setEditID(ele.ID);
        if (btnId == 1) {
        } else if (btnId == 2) {
            handleOpen('Edit', ele.ID);
        } else if (btnId == 3) {
            handleOpen('Delete', ele.ID);
        } else if (btnId == 4) {
            wsGenerateAPI(ele.ID)
        } else if (btnId == 5) {
            const textToCopy = ele.Controller;
            if (textToCopy != "") {
                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        handleNotification(true, "APIController copied to clipboard ", 'info');
                    })
                    .catch((error) => {
                        handleNotification(true, 'Unable to copy text to clipboard', 'error');
                    });
            } else {
                handleNotification(true, 'Unable to copy text to clipboard', 'error');
            }

        } else if (btnId == 6) {
            const textToCopy = ele.Caller;
            if (textToCopy != "") {
                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        handleNotification(true, "APIController copied to clipboard ", 'info');
                    })
                    .catch((error) => {
                        handleNotification(true, 'Unable to copy text to clipboard', 'error');
                    });
            } else {
                handleNotification(true, 'Unable to copy text to clipboard', 'error');
            }

        } else if (btnId == 7) {
            const textToCopy = ele.Parameters;
            if (textToCopy != "") {
                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        handleNotification(true, "APIController copied to clipboard ", 'info');
                    })
                    .catch((error) => {
                        handleNotification(true, 'Unable to copy text to clipboard', 'error');
                    });
            } else {
                handleNotification(true, 'Unable to copy text to clipboard', 'error');
            }

        } else if (btnId == 8) {
            const textToCopy = ele.API;
            if (textToCopy != "") {
                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        handleNotification(true, "APIController copied to clipboard ", 'info');
                    })
                    .catch((error) => {
                        handleNotification(true, 'Unable to copy text to clipboard', 'error');
                    });
            } else {
                handleNotification(true, 'Unable to copy text to clipboard', 'error');
            }
        } else if (btnId == 9) {
            const textToCopy = ele.Model;
            if (textToCopy != "") {
                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        handleNotification(true, "APIController copied to clipboard ", 'info');
                    })
                    .catch((error) => {
                        handleNotification(true, 'Unable to copy text to clipboard', 'error');
                    });
            } else {
                handleNotification(true, 'Unable to copy text to clipboard', 'error');
            }

        };
    });

    // Sorting the Data
    const applySorting = (key, ascending) => {
        if (key !== 'action') {
            apiParamWithFilter(field, CurrentPage, RecordPerPage, key, ascending ? 'ASC' : 'DESC');
            setSortingIcon(true);
            setSorting({ field: key, ascending: ascending });
            setSortDirection(ascending ? 'ASC' : 'DESC');
        };
    };

    // Searching the Data
    const handleSearch = (key, e) => {
        const { name, value } = e.target;
        setField({ ...field, [name]: value });
        setSearchingInput({ field: key, key: e.target.value.toString() });
    };

    const handleKeypress = (e, query) => {
        const { key } = searchingInput;
        if (key != null) {
            if (key.length < 0 || key.length == 0 || key.length == -1) {
                apiParamWithFilter(field, CurrentPage, RecordPerPage, SortExpression, SortDirection);
            }
            else if (e.keyCode === 13) {
                apiParamWithFilter(field, CurrentPage, RecordPerPage, SortExpression, SortDirection);
            };
        };
    };

    const handleSelect = (query, e) => {
        setSearchingInput({ field: query, query: e.target.value });
        const { value } = e.target;
        let data = { ...field, [query]: value, CurrentPage, RecordPerPage, SortExpression, SortDirection };
        apiParamWithFilter(data);
    };

    // Pagination for the Grid
    const handleChangePage = (event, newpage) => {
        const page = newpage + 1;
        apiParamWithFilter(field, page, RecordPerPage, SortExpression, SortDirection);
        setCurrentPage(newpage);
    };

    const handleChangeRowsPerPage = (event) => {
        apiParamWithFilter(field, CurrentPage, parseInt(event.target.value), SortExpression, SortDirection);
        setRecordPerPage(parseInt(event.target.value));
    };

    const handleGotoPage = (e) => {
        setGotoPage(Math.abs(e.target.value));
    };

    const handleSubmitGotoPage = (e) => {
        if (e.keyCode === 13) {
            if (gotoPage == "") {
                apiParamWithFilter(field, 1, RecordPerPage, SortExpression, SortDirection);
                setCurrentPage(0);
            } else {
                apiParamWithFilter(field, gotoPage, RecordPerPage, SortExpression, SortDirection);
                setCurrentPage(gotoPage - 1);
            };
        };
    };

    const wsDeleteMyAPI = (ID) => {
        setInitialState({ ...InitialState, loading: true });
        APIController.DeleteMyAPI(ID)
            .then((response) => {
                setInitialState({ ...InitialState, open: false, loading: false })
                if (response.error == null) {
                    handlebind();
                    handleNotification(true, response.object.message, 'info');
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(true, response.error.message, 'error');
                };
            });
    };
    const wsGenerateAPI = (ID) => {

        APIController.GenerateAPI(ID)
            .then((response) => {

                if (response.error == null) {
                    handlebind();
                    handleNotification(true, response.object.message, 'info');
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(true, response.error.message, 'error');
                };
            });
    };

    const handlebind = () => {
        let apiPerameter = fieldName();
        WsGetMyAPIs(...apiPerameter, CurrentPage, RecordPerPage, SortExpression, SortDirection);
    };

    const handleNotification = (val, msg, type) => {
        setShowNotification(val);
        setMessage(msg);
        setToastType(type);
    };

    const handleDelete = () => {
        wsDeleteMyAPI(EditID);
    };

    const handleClose = () => {
        setInitialState({ ...InitialState, open: false });
    };

    const handleOpen = (onPress, EditID) => {
        if (onPress == 'Add') {
            setInitialState({ ...InitialState, PopupTitle: 'Add MyAPIs', PopupActionText: 'Add', Action: 0, open: true, EditID: EditID });
        } else if (onPress == 'Edit') {
            setInitialState({ ...InitialState, PopupTitle: 'Edit MyAPIs', PopupActionText: 'Edit', Action: 1, open: true, EditID: EditID });
        } else if (onPress == 'Delete') {
            setInitialState({ ...InitialState, PopupTitle: 'Delete MyAPIs', PopupActionText: 'Delete', Action: 2, open: true, EditID: EditID });
        };
    };

    return (
        <div>

            {ShowNotification &&
                <Notification
                    handleClose={handleNotification.bind(this)}
                    message={Message}
                    variant={ToastType}
                />}
            {IsloadList ? <CommonLoader /> :
                <DataGrid
                    tableStyle={tableStyle}
                    button={button}
                    gridData={gridData}
                    IsFilter={IsFilter}
                    tableHeader={tableHeader}
                    setGridData={setGridData}
                    sorting={sorting}
                    shortingIcon={shortingIcon}
                    searchingInput={searchingInput}
                    showSelectAllBox={showSelectAllBox}
                    setShowSelectAllBox={setShowSelectAllBox}
                    checked={checked}
                    page={CurrentPage}
                    pageSize={RecordPerPage}
                    loading={loading}
                    MaxRows={MaxRows}
                    handleTopBtnClick={handleTopBtnClick}
                    handleCheckBox={handleCheckBox}
                    hendleCheckedAll={hendleCheckedAll}
                    handleActionBtnClick={handleActionBtnClick}
                    applySorting={applySorting}
                    handleSearch={handleSearch}
                    handleKeypress={handleKeypress}
                    handleSelect={handleSelect}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleGotoPage={handleGotoPage}
                    handleSubmitGotoPage={handleSubmitGotoPage} />}

            {InitialState.open &&
                <CommonModal
                    Title={InitialState.PopupTitle}
                    ActionText={InitialState.PopupActionText}
                    IsDeletePopup={InitialState.Action == 2 ? true : false}
                    IsLoading={InitialState.loading}
                    onClose={handleClose.bind(this)}
                    onSave={handleDelete.bind(this)} IsScroll >
                    {InitialState.Action <= 1 && <MyApiForm EditID={InitialState.EditID} onClose={handleClose.bind(this)} handlebind={handlebind.bind(this)} handleNotification={handleNotification.bind(this)} />}
                </CommonModal>
            }
        </div>
    );
}