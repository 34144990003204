
import './App.css';
import { Route, Routes } from 'react-router-dom';
import ListProjectList from './Pages/Project/ProjectList';
import ControllerList from './Pages/Controller/ControllerList';
import MyAPIsList from './Pages/MyApi/MyApiList';
import ScreensList from './Pages/Screens/ScreensList';
import ScreensForm from './Pages/Screens/ScreensForm';
import Navbar from './Camponets/Navbar/NavBar';
import ConfigureTables from './Pages/ConfigureTables/ConfigureTables';
import ConfigureField from './Pages/ConfiguredFields/ConfiguredFields';
import StoredProcedures from './Pages/StoredProcedures/StoredProcedures';
import TableList from './Pages/Tables/TableList';
import GetFieldsList from './Pages/GetFields/GetFieldsList';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<ListProjectList />} />
        <Route path="/controllerlist" element={<ControllerList />} />
        <Route path="/myapislist" element={<MyAPIsList />} />
        <Route path="/screenslist" element={<ScreensList />} />
        <Route path="/screenform" element={<ScreensForm />} />
        <Route path="/tablelist" element={<TableList />} />
        <Route path="/configuretables" element={<ConfigureTables />} />
        <Route path="/configurefield" element={<ConfigureField />} />
        <Route path="/storedprocedures" element={<StoredProcedures />} />
        <Route path="/getfieldslist" element={<GetFieldsList />} />

      </Routes>
    </>
  );
}

export default App;
