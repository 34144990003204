
import React, { useEffect, useState } from 'react';
import * as APIController from '../../APIs/APIController';
import DataGrid from '../../Camponets/DataGrid/DataGrid';
import CommonModal from "../../Camponets/Modal/CommonModal";
import Notification from '../../Camponets/Notification/Notification';
import CommonLoader from "../../Camponets/CommonLoader/CommonLoader";
import { AppConstant, TableColor } from "../../Camponets/AppConstant";
import ProjectForm from './ProjectForm';


const tableStyle = [
  { headerName: 'Action', actionType: 1, width: "80px", position: 'center', actionButton: [{ id: 2, name: 'Edit', icon: "", color: "error", size: "18px", type: 'icon' }, { id: 3, name: 'Delete', icon: "", color: "success", size: "20px", type: "icon" },] },
  { fieldName: 'Name', headerName: 'Name', actionType: 2 },
  { fieldName: 'ConnectionString', headerName: 'Connection String', actionType: 2 },
  { fieldName: 'IsDefaultLABEL', headerName: 'Is Default?', width: "95px", actionType: 3, position: 'center', selectOption: [{ name: 'All', value: '' }, { name: 'YES', value: 1 }, { name: 'NO', value: 0 }], showAsTag: true },
  { fieldName: 'IsActiveLABEL', headerName: 'Is Active?', width: "95px", actionType: 3, position: 'center', selectOption: [{ name: 'All', value: '' }, { name: 'YES', value: 1 }, { name: 'NO', value: 0 }], showAsTag: true },
]

const button = [
  { id: 4, color: "", name: "Add", type: 'icon' },
];

export default function Countries() {
  const [IsFilter, setIsFilter] = useState(true);
  const [tableHeader, setTableHeader] = useState("Projects")
  const [field, setField] = useState()
  const [gridData, setGridData] = useState([]);
  const [chekedAll, setChekedAll] = useState(false);
  const [checked, setChecked] = useState([]);
  const [showSelectAllBox, setShowSelectAllBox] = useState(false)
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ field: 'name', ascending: false });
  const [searchingInput, setSearchingInput] = useState({ field: "", searching: false });
  const [shortingIcon, setSortingIcon] = useState(false);
  const [CurrentPage, setCurrentPage] = useState(0);
  const [RecordPerPage, setRecordPerPage] = useState(AppConstant.DefaultRecordsPerPage);
  const [SortDirection, setSortDirection] = useState(AppConstant.DefaultSort);
  const [SortExpression, setSortExpression] = useState("Name");
  const [MaxRows, setMaxRows] = useState(0);
  const [gotoPage, setGotoPage] = useState();
  const [IsloadList, setIsloadList] = useState(true);
  const [ShowNotification, setShowNotification] = useState(false);
  const [ToastType, setToastType] = useState('');
  const [Message, setMessage] = useState('');
  const [EditID, setEditID] = useState(0);
  const [InitialState, setInitialState] = useState({
    PopupTitle: "", PopupActionText: "", open: "", Action: "", EditID: "", loading: false
  });

  //  Get Required Data for the Grid
  const WsGetProjects = (Name,ConnectionString,  IsDefault,IsActive, ) => {
    console.log("ghsgafg");
    APIController.GetProjects(Name,ConnectionString, IsDefault,IsActive, )
      .then((response) => {
        if (response.error == null) {
          let finaldata = response.object.data;
          setGridData(finaldata);
          setMaxRows(response.object.totalRecords);
          setIsloadList(false);
        };
      });
  };

  const fieldName = () => {
    const tableField = tableStyle.filter((ele, ind) => {
      return ele.actionType === 2 || ele.actionType === 3;
    });
    const setTableField = tableField.map((ele, ind) => {
      const { fieldName } = ele;
      return fieldName;
    });
    const obj = {};
    setTableField.forEach((element, index) => {
      obj[element] = "";
    });
    setField(obj);
    const apiArry = Object.values(obj);
    return apiArry.flat(1);
  };

  const apiParamWithFilter = (field, CurrentPage, RecordPerPage, SortExpression, SortDirection) => {
    const apiArry = Object.values(field);
    const margeArry = apiArry.flat(1);
    WsGetProjects(...margeArry, CurrentPage, RecordPerPage, SortExpression, SortDirection);
  };

  useEffect(() => {
    let apiPerameter = fieldName();
    WsGetProjects(...apiPerameter, CurrentPage, RecordPerPage, SortExpression, SortDirection);
    setLoading(false);
  }, []);

  // Show Button for Adding the Data
  const handleTopBtnClick = ((e, btnId) => {
    if (btnId == 4) {
      handleOpen('Add', 0);
    };
  });

  // Select All Checkboxes
  const hendleCheckedAll = (e) => {
    setChekedAll(e.target.checked);
    if (e.target.checked) {
      const allRows = gridData.map((c) => c);
      setChecked(allRows);
    } else {
      setChecked([]);
    };
  };

  // Select the Checkbox
  const handleCheckBox = (e, ele, eleId, buttonId) => {
    setShowSelectAllBox(true);
    if (e.target.checked) {
      setChecked([...checked, ele]);
    } else {
      setChecked(checked.filter((item) => item.ID != ele.ID));
    };
  };

  // Icons for Action
  const handleActionBtnClick = ((ele, eleId, btnId) => {
    setEditID(ele.ID);
    if (btnId == 1) {
    } else if (btnId == 2) {
      handleOpen('Edit', ele.ID);
    } else if (btnId == 3) {
      handleOpen('Delete', ele.ID);
    };
  });

  // Sorting the Data
  const applySorting = (key, ascending) => {
    if (key !== 'action') {
      apiParamWithFilter(field, CurrentPage, RecordPerPage, key, ascending ? 'ASC' : 'DESC');
      setSortingIcon(true);
      setSorting({ field: key, ascending: ascending });
      setSortDirection(ascending ? 'ASC' : 'DESC');
    };
  };

  // Searching the Data
  const handleSearch = (key, e) => {
    const { name, value } = e.target;
    setField({ ...field, [name]: value });
    setSearchingInput({ field: key, key: e.target.value.toString() });
  };

  const handleKeypress = (e, query) => {
    const { key } = searchingInput;
    if (key != null) {
      if (key.length < 0 || key.length == 0 || key.length == -1) {
        apiParamWithFilter(field, CurrentPage, RecordPerPage, SortExpression, SortDirection);
      }
      else if (e.keyCode === 13) {
        apiParamWithFilter(field, CurrentPage, RecordPerPage, SortExpression, SortDirection);
      };
    };
  };

  const handleSelect = (query, e) => {
    setSearchingInput({ field: query, query: e.target.value });
    const { value } = e.target;
    let data = { ...field, [query]: value, CurrentPage, RecordPerPage, SortExpression, SortDirection };
    apiParamWithFilter(data);
  };

  // Pagination for the Grid
  const handleChangePage = (event, newpage) => {
    const page = newpage + 1;
    apiParamWithFilter(field, page, RecordPerPage, SortExpression, SortDirection);
    setCurrentPage(newpage);
  };

  const handleChangeRowsPerPage = (event) => {
    apiParamWithFilter(field, CurrentPage, parseInt(event.target.value), SortExpression, SortDirection);
    setRecordPerPage(parseInt(event.target.value));
  };

  const handleGotoPage = (e) => {
    setGotoPage(Math.abs(e.target.value));
  };

  const handleSubmitGotoPage = (e) => {
    if (e.keyCode === 13) {
      if (gotoPage == "") {
        apiParamWithFilter(field, 1, RecordPerPage, SortExpression, SortDirection);
        setCurrentPage(0);
      } else {
        apiParamWithFilter(field, gotoPage, RecordPerPage, SortExpression, SortDirection);
        setCurrentPage(gotoPage - 1);
      };
    };
  };

  const wsDeleteProject = (ID) => {
    setInitialState({ ...InitialState, loading: true });
    APIController.DeleteProject(ID)
      .then((response) => {
        setInitialState({ ...InitialState, open: false, loading: false })
        if (response.error == null) {
          handlebind();
          handleNotification(true, response.object.message, 'info');
        }
        else if (response.error.message && response.error.message.length > 0) {
          handleNotification(true, response.error.message, 'error');
        };
      });
  };

  const handlebind = () => {
    let apiPerameter = fieldName();
    WsGetProjects(...apiPerameter, CurrentPage, RecordPerPage, SortExpression, SortDirection);
  };

  const handleNotification = (val, msg, type) => {
    setShowNotification(val);
    setMessage(msg);
    setToastType(type);
  };

  const handleDelete = () => {
    wsDeleteProject(EditID);
  };

  const handleClose = () => {
    setInitialState({ ...InitialState, open: false });
  };

  const handleOpen = (onPress, EditID) => {
    if (onPress == 'Add') {
      setInitialState({ ...InitialState, PopupTitle: 'Add Project', PopupActionText: 'Add', Action: 0, open: true, EditID: EditID });
    } else if (onPress == 'Edit') {
      setInitialState({ ...InitialState, PopupTitle: 'Edit Project', PopupActionText: 'Edit', Action: 1, open: true, EditID: EditID });
    } else if (onPress == 'Delete') {
      setInitialState({ ...InitialState, PopupTitle: 'Delete Project', PopupActionText: 'Delete', Action: 2, open: true, EditID: EditID });
    };
  };

  return (
    <div>

      {ShowNotification &&
        <Notification
          handleClose={handleNotification.bind(this)}
          message={Message}
          variant={ToastType}
        />}
      {IsloadList ? <CommonLoader /> :
        <DataGrid
          tableStyle={tableStyle}
          button={button}
          gridData={gridData}
          IsFilter={IsFilter}
          tableHeader={tableHeader}
          setGridData={setGridData}
          sorting={sorting}
          shortingIcon={shortingIcon}
          searchingInput={searchingInput}
          showSelectAllBox={showSelectAllBox}
          setShowSelectAllBox={setShowSelectAllBox}
          checked={checked}
          page={CurrentPage}
          pageSize={RecordPerPage}
          loading={loading}
          MaxRows={MaxRows}
          handleTopBtnClick={handleTopBtnClick}
          handleCheckBox={handleCheckBox}
          hendleCheckedAll={hendleCheckedAll}
          handleActionBtnClick={handleActionBtnClick}
          applySorting={applySorting}
          handleSearch={handleSearch}
          handleKeypress={handleKeypress}
          handleSelect={handleSelect}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleGotoPage={handleGotoPage}
          handleSubmitGotoPage={handleSubmitGotoPage} />}

      {InitialState.open &&
        <CommonModal
          Title={InitialState.PopupTitle}
          ActionText={InitialState.PopupActionText}
          IsDeletePopup={InitialState.Action == 2 ? true : false}
          IsLoading={InitialState.loading}
          onClose={handleClose.bind(this)}
          onSave={handleDelete.bind(this)} IsScroll >
          {InitialState.Action <= 1 && <ProjectForm EditID={InitialState.EditID} onClose={handleClose.bind(this)} handlebind={handlebind.bind(this)} handleNotification={handleNotification.bind(this)} />}
        </CommonModal>
      }
    </div>
  );
}