import { CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import * as APIController from '../../APIs/APIController.js';
import CommonButton from "../../Camponets/Button/CommonButton.js";
import DropDown from "../../Camponets/Select/DropDown.js";
import TextField from "../../Camponets/TextField/TextField.js";
import CommonSwitch from "../../Camponets/Switch/CommonSwitch.js";
import CheckBox from '../../Camponets/CheckBox/CheckBox.js';
import { ThreeSixty } from '@material-ui/icons';

class MyApiForm extends React.Component {
    state = {
        ID: '',
        ProjectID: '',
        TableID: '',
        Name: "",
        TypeID: "",
        EditID: '0',
        IsActive: true,
        ProjectOption: [],
        TypeOption: [{ 'Name': "Custom", 'ID': 0 }, { 'Name': "Add/Edit", 'ID': 1 }, { 'Name': "Delete", 'ID': 2 }, { 'Name': "GetByID", 'ID': 3 }, { 'Name': "List", 'ID': 4 }, { 'Name': "ForDropdownBox", 'ID': 5 }],
        Response: '',
        OperationID: '',
        ResponseOption: [{ 'Name': "Status", 'ID': 1 }, { 'Name': "Data", 'ID': 2 }, { 'Name': "All", 'ID': 3 },],


        ApiOption: [],
        TableOption: [],
        DistributorIDs: [],
        IsValid: false,
        IsLoading: false,
        IsloadData: false,
        IsRequired: false,
        Close: false
    }

    componentDidMount() {

        this.props.EditID == 0 ? this.wsGetProjectForDropdown() : this.wsGetMyAPIByID(this.props.EditID);
    }
    GetRightsNoForDropDown = () => {
        let array = [];
        for (let i = 1; i <= 5; i++) {
            array.push()
        }
        this.setState({ option: array, IsloadData: false })
    }

    wsGetProjectForDropdown() {
        this.setState({IsloadData:true})
        APIController.GetProjectForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ProjectOption: response.object.data,ProjectID:response.object.data[0].ID })
                    this.wsGetTableForDropdown(response.object.data[0].ID)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    wsGetFieldForDropdown(ProjectID, TableID) {
        APIController.GetFieldForDropdown(ProjectID, TableID)
            .then((response) => {
                if (response.error == null) {
                    const newApiOption = []
                    const newJson = response.object.data.forEach((item, ind) => {
                        return newApiOption.push({ ...item, 'FieldID': item.ID, 'IsChecked': 0, "IsMandatory": false })
                    })

                    this.setState({ ApiOption: newApiOption })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    wsGetTableForDropdown(ID) {
        APIController.GetTableForDropdown(ID)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ TableOption: response.object.data,IsloadData:false })

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }

    handleChange = (e) => {
        if (e.target.name == "TableID") {
            const tablename = this.state.TableOption.filter((item, ind) => {
                if (e.target.value == item.ID) {
                    return this.setState({ Name: item.Name })
                }
            })
            { this.props.EditID == 0 && this.wsGetFieldForDropdown(this.state.ProjectID, e.target.value) }
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        } else if (e.target.name == "ProjectID") {
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
           
        } else {
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        }

    }

    handleChangecheckBox = (e, index, value,) => {
        const { name, checked } = e.target
        const DistributorID = []
        if (checked == true) {
            this.setState({ [index]: checked, DistributorIDs: [...this.state.DistributorIDs, { 'FieldID': value }] })
        } else {
            const newarry = this.state.DistributorIDs.filter((item, ind) => {
                return value != item.FieldID
            })
            this.setState({ [index]: checked, DistributorIDs: newarry })
        }
    }

    handleChangeswitch = (e, index, type) => {
        const newData = [...this.state.ApiOption];
        if (type == 'checkbox') {
            newData[index]["IsChecked"] = e.target.checked == true ? 1 : 0;
        } else {

            newData[index]["IsMandatory"] = e.target.checked;
        }
        this.setState({ ApiOption: newData })
    }

    handleValidate() {

        if (this.state.ProjectID == '') {
            this.setState({ IsValid: true, })
        } else if (this.state.TableID == '') {
            this.setState({ IsValid: true, })
        } else {
            const newJson = this.state.ApiOption.map((item, ind) => {
                if (item.IsChecked == 1) {
                    return item
                }
            })
            const allJson = newJson.filter((item) => {
                if (item != '' || undefined) {
                    return item
                }
            })
            const arry = []
            const newarry = allJson.map((item) => {
                return arry.push({ "FieldID": item.ID, "IsMandatory": item.IsMandatory })
            })

            this.wsAddEditMyAPI(this.props.EditID, this.state.ProjectID, this.state.TableID, this.state.Name, this.state.TypeID, this.state.OperationID, this.state.Response, arry)
            this.setState({ Close: true })
        }
    }


    handleAdd() {
        if (this.state.ProjectID == '') {
            this.setState({ IsValid: true, })
        } else if (this.state.TableID == '') {
            this.setState({ IsValid: true, })
        } else if (this.state.TypeID == '') {
            this.setState({ IsValid: true, })
        } else {

            const newJson = this.state.ApiOption.map((item, ind) => {
                if (item.IsChecked == 1) {
                    return item
                }
            })

            const allJson = newJson.filter((item) => {
                if (item != '' || undefined) {
                    return item
                }
            })
            const arry = []
            const newarry = allJson.map((item) => {
                return arry.push({ "FieldID": item.FieldID, "IsMandatory": item.IsMandatory })
            })

            this.wsAddEditMyAPI(this.props.EditID, this.state.ProjectID, this.state.TableID, this.state.Name, this.state.TypeID,this.state.OperationID, this.state.Response, arry)
        }
       this.wsGetFieldForDropdown(this.state.ProjectID, this.state.TableID)
        this.setState({ DistributorIDs: [] })
    }

    wsGetMyAPIByID(ID) {
        this.setState({ IsloadData: true })
        APIController.GetMyAPIByID(ID)
            .then((response) => {
                if (response.error == null) {
                    this.setState({
                        ProjectID: response.object.data.ProjectID,
                        TableID: response.object.data.TableID,
                        Name: response.object.data.Name,
                        TypeID: response.object.data.TypeID,
                        ProjectOption: (response.object.data.ProjectName),
                        TableOption: (response.object.data.TableName),
                        OperationID: (response.object.data.OperationID),
                        Response: (response.object.data.ResponseObject),
                        ApiOption: (response.object.data.JSON),
                    })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
                this.setState({ IsloadData: false })
            })
    }

    wsAddEditMyAPI(ID, ProjectID, TableID, Name, TypeID, OperationID, Response, json) {
        this.setState({ IsLoading: true })
        APIController.AddEditMyAPI(ID, ProjectID, TableID, Name, TypeID, OperationID, Response, json)
            .then((response) => {
                this.setState({ IsLoading: false })
                if (response.error == null) {
                    this.props.handlebind()
                    this.props.handleNotification(true, response.object.message,)
                    { this.state.Close && this.props.onClose() }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    handleNotification(val, msg, type) {
        this.setState({ ShowNotification: val, Message: msg, ToastType: type })
    }

    render() {
        const { ProjectID, ProjectOption, TableID, TableOption, Response, OperationID, ResponseOption, Name, TypeID, ApiOption, IsValid, IsLoading, IsloadData, TypeOption, IsRequired } = this.state;
        console.log('TypeID', TypeID);
        return (
            IsloadData ? <CircularProgress /> :
                <Grid container lg={12} justify='center' >
                    <Grid container justify="space-between">
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <DropDown name={'ProjectID'}  value={ProjectID} id={'Project Name'} onChange={this.handleChange} optionText={ProjectOption} helper={'Select Project Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <DropDown name={'TableID'} value={TableID} id={'Table Name'} onChange={this.handleChange} optionText={TableOption} helper={'Select Table Name'} IsValid={IsValid} />
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'Name'} id={'Name'} onChange={this.handleChange} value={Name} helper={'Please Enter Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <DropDown name={'TypeID'} value={TypeID} id={'Type'} onChange={this.handleChange} optionText={TypeOption} helper={'Select Type'} IsValid={IsValid} />
                        </Grid>

                        { TypeID == "0" && <Grid container justify="space-between">
                            <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                                <TextField name={'OperationID'} id={'OperationID'} onChange={this.handleChange} value={OperationID} helper={'Please Enter Operation ID '} IsValid={IsValid} />
                            </Grid>
                            <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                                <DropDown name={'Response'} value={Response} id={'Response'} onChange={this.handleChange} optionText={ResponseOption} helper={'Select Response '} IsValid={IsValid} />
                            </Grid>
                        </Grid>}
                    </Grid>

                    {Name != "" && ApiOption.map((ele, index) => {
                        console.log('ele.IsMandatory', ele.IsMandatory);
                        return (
                            <Grid container xl={12} xs={12} style={{ borderBottom: '2px solid black' }}>

                                <Grid container xl={6} md={6} sm={6} xs={6}>
                                    <CheckBox color='green' name={ele.Name} checked={ApiOption[index].IsChecked} onChange={(e) => this.handleChangeswitch(e, index, "checkbox")} label={ele.Name} />
                                    {/* <CheckBox color='green' name={ele.Name} checked={this.state.index} onChange={(event) => this.handleChangecheckBox(event, index, ele.ID)} label={ele.Name} /> */}
                                </Grid>
                                <Grid container xl={6} md={6} sm={6} xs={6}>
                                    {/* <CommonSwitch name={'IsDisabled'} value={AllFieldData[ind].IsDisabled} id={'IsDisabled'} onChange={(event) => this.handleChangeField('', event, item.ID, ind)} label={'IsDisabled'} checked={AllFieldData[ind].IsDisabled} /> */}
                                    <CommonSwitch name={'IsMandatory'} value={ele.IsMandatory} id={'IsMandatory'} onChange={(e) => this.handleChangeswitch(e, index)} label={'IsMandatory'} checked={ele.IsMandatory} />

                                    {/* <CommonSwitch name={'IsMandatory'} value={ApiOption[index].IsDisabled} id={'IsMandatory'} onChange={(e) => this.handleChangeswitch(e, index)} label={'IsMandatory'} checked={this.props.EditID!=0?ele.IsMandatory:ApiOption[index].IsDisabled} /> */}
                                </Grid>

                            </Grid>
                        )
                    })}
                    <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <DialogActions>
                            <CommonButton IsLoading={IsLoading} onClick={() => this.handleValidate()} color="primary">{'Save'}</CommonButton>
                            <CommonButton IsLoading={IsLoading} onClick={() => this.handleAdd()} color="primary">{'Add'}</CommonButton>
                            <CommonButton onClick={() => this.props.onClose()} color="secondary">{'Close'}</CommonButton>
                        </DialogActions>
                    </Grid>
                </Grid>
        );
    }
}
export default MyApiForm;
