
import React from 'react';
import TextField from "../../Camponets/TextField/TextField";
import CommonButton from "../../Camponets/Button/CommonButton";
import CommonSwitch from "../../Camponets/Switch/CommonSwitch";
import Grid from '@material-ui/core/Grid';
import * as APIController from '../../APIs/APIController.js';
import { CircularProgress } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions';
import DropDown from "../../Camponets/Select/DropDown";

class ConfigureField extends React.Component {
    state = {
        ProjectName: '',
        TableName: '',
        TableID: '',
        TableOption: [],
        FriendlyName: '',
        ParameterName: '',
        Name: '',
        Type: '',

        ControllerID: '',
        ProjectNameOption: [],
        ConfigureTablesData: [],
        Tables: [],
        touched: false,
        IsValid: '',
        option: [],
        IsLoading: false,
        IsloadData: false
    }

    componentDidMount() {
        this.wsGetProjectForDropdown()
    }

    wsGetProjectForDropdown() {
        APIController.GetProjectForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ProjectNameOption: response.object.data, });
                    this.wsGetTableForDropdown()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message);
                }
            });
    };
    wsGetTableForDropdown() {
        APIController.GetTableForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ TableOption: response.object.data, });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message);
                }
            });
    };

    wsGetConfiguredFields(ProjectID, TableID, TableName) {
        APIController.GetConfiguredFields(ProjectID, TableID, TableName)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ConfigureTablesData: response.object.data, });
                   

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message);
                }
            });
    };
    wsSaveConfiguredFields(ProjectID, TableID, Fields) {
        APIController.SaveConfiguredFields(ProjectID, TableID, Fields)
            .then((response) => {
                if (response.error == null) {
                    this.props.handlebind()
                    this.props.handleNotification(true, response.object.message,)
                    this.props.onClose()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message,"error");
                }
            });
    };


    handleChange = (e, index) => {


        if (e.target.name == "ProjectName" || e.target.name == 'TableName') {
            const field = this.state.TableOption.length == 0 ? '' : this.state.TableOption.filter((item, ind) => {
                if (item.ID == e.target.value) {
                    this.setState({ TableName: item.Name })
                    this.wsGetConfiguredFields(this.state.ProjectName, item.ID, item.Name)
                }
            })

            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        } else {
            const newData = [...this.state.ConfigureTablesData];
            newData[index][e.target.name] = e.target.value;
            this.setState({ ConfigureTablesData: newData })

            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        }

    }

    handleValidate() {
        console.log('ConfigureTablesData', this.state.ConfigureTablesData);

        if (this.state.ProjectName == '') {
            this.setState({ IsValid: true, })
        }
        else {

            //Add Edit Api Here
            this.wsSaveConfiguredFields(this.state.ProjectName, this.state.TableName, this.state.ConfigureTablesData)
        }

    }

    render() {
        const { FriendlyName, ParameterName, Name, Type, ProjectName, TableID, TableOption, ConfigureTablesData, TableName, ConfigureTablesOption, ControllerID, ControllersOption, Controller, StoredProcedure, Singular, Plural, ControllerOption, ProjectNameOption, StoredProcedureOption, IsValid, IsLoading, IsloadData, option } = this.state;
        if (IsloadData)
            return <Grid container lg={12} justify='center' style={{ width: 150 }}>
                <CircularProgress />
            </Grid>
        else return (

            <form  onSubmit={(e) => { e.preventDefault(); this.handleValidate(); }}>
                
                <Grid container lg={12} >
                    <Grid container xl={6} md={6} sm={6} xs={6}>
                        <DropDown name={'ProjectName'} value={ProjectName} id={'Project Name'} onChange={this.handleChange} optionText={ProjectNameOption} helper={'Select Project Name'} IsValid={IsValid} />
                    </Grid>
                    <Grid container xl={6} md={6} sm={6} xs={6}>
                        <DropDown name={'TableName'} value={TableName} id={'Table Name'} onChange={this.handleChange} optionText={TableOption} helper={'Select TableName'} IsValid={IsValid} />
                    </Grid>

                    {ConfigureTablesData.map((ele, index) => {
                        
                        return (
                            <Grid container xl={12} xs={12} style={{ borderBottom: '2px solid black',padding:'10px 0px' }}>
                                
                                <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                                    <Grid container >
                                        <TextField name={`Name`} value={ele.Name} id={`Name`} onChange={(e) => this.handleChange(e, index)} helper={`Please Enter Name`} IsValid={IsValid} />
                                    </Grid>
                                    <Grid container >
                                        <TextField name={`ParameterName`} value={ele.ParameterName} id={`Parameter Name`} onChange={(e) => this.handleChange(e, index)} helper={`Please Enter Parameter Name`} IsValid={IsValid} />
                                    </Grid>
                                    <Grid container >
                                        <TextField name={`FriendlyName`} value={ele.FriendlyName} id={`Friendly Name`} onChange={(e) => this.handleChange(e, index)} helper={`Please Enter Friendly Name`} IsValid={IsValid} />
                                    </Grid>
                                    <Grid container >
                                        <TextField name={`Type`} value={ele.Type} id={`Type`} onChange={(e) => this.handleChange(e, index)} helper={`Please Enter Type`} IsValid={IsValid} />
                                    </Grid>
                                    <Grid container >
                                        <TextField name={`SequenceNo`} value={ele.SequenceNo} id={`Sequence No`} onChange={(e) => this.handleChange(e, index)} helper={`Please Enter Sequence No`} IsValid={IsValid} />
                                    </Grid>
                                </div>
                            </Grid>

                        )
                    })}

                    {/* <Grid container xl={6} md={6} sm={6} xs={6}>
                        <DropDown name={'Controller'} value={Controller} id={'Select Controller'} onChange={this.handleChange} optionText={ControllerOption} helper={'Select Controller'} IsValid={IsValid} />
                    </Grid>
                    <Grid container xl={6} md={6} sm={6} xs={6}>
                        <DropDown name={'StoredProcedure'} value={StoredProcedure} id={'Select StoredProcedure'} onChange={this.handleChange} optionText={StoredProcedureOption} helper={'Select StoredProcedure'} IsValid={IsValid} />
                    </Grid>
                    <Grid container xl={6} md={6} sm={6} xs={6}>
                        <TextField name={'Singular'} value={Singular} id={'Singular'} onChange={this.handleChange} helper={'Please Enter Singular'} IsValid={IsValid} />
                    </Grid>
                    <Grid container xl={6} md={6} sm={6} xs={6}>
                        <TextField name={'Plural'} value={Plural} id={'Plural'} onChange={this.handleChange} helper={'Please Enter Plural'} IsValid={IsValid} />
                    </Grid> */}
                    {/* <CommonSwitch name={'IsActive'} value={IsActive} id={'IsActive'} onChange={(e) => this.setState({ IsActive: e.target.checked })} label={'Is Active?'} checked={IsActive} /> */}

                    <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <DialogActions>
                            <CommonButton IsLoading={IsLoading} type='submit' color="primary">{'Save'}</CommonButton>
                            <CommonButton onClick={() => this.props.onClose()} color="secondary">{'Close'}</CommonButton>
                        </DialogActions>
                    </Grid>
                </Grid>
            </form>
        );
    }
}
export default ConfigureField;
