import { CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import * as APIController from '../../APIs/APIController.js';
import CommonButton from "../../Camponets/Button/CommonButton.js";
import TextField from "../../Camponets/TextField/TextField.js";
import CommonSwitch from "../../Camponets/Switch/CommonSwitch.js";
import DropDown from "../../Camponets/Select/DropDown.js";


class GetFieldsForm extends React.Component {
    state = {
        ProjectID: '',
        TableID: '',
        ID: '',
        Name: '',
        Type: '',
        ParameterName: '',
        FriendlyName: '',
        SequenceNo: '',
        ProjectOption: [],
        TableOption: [],
        IsValid: false,
        IsLoading: false,
        IsloadData: false
    }

    componentDidMount() {
        this.props.EditID != 0 ? this.wsGetFieldByID(this.props.EditID)
            : this.wsGetProjectForDropdown()

    }
    wsGetProjectForDropdown() {
        APIController.GetProjectForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ProjectOption: response.object.data })
                    this.wsGetTableForDropdown()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    wsGetTableForDropdown() {
        APIController.GetTableForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ TableOption: response.object.data })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    handleValidate() {
        if (this.state.Name == '') {
            this.setState({ IsValid: true, })
        } else {
            this.wsAddEditField(this.props.EditID, this.state.ProjectID, this.state.TableID, this.state.Name, this.state.Type, this.state.ParameterName, this.state.FriendlyName, this.state.SequenceNo,)
        }
    }


    wsGetFieldByID(ID) {
        this.setState({ IsloadData: true })
        APIController.GetFieldByID(ID)
            .then((response) => {
                if (response.error == null) {
                    let data = response.object.data[0]
                    this.setState({
                        ProjectID: data.ProjectID,
                        TableID: data.TableID,
                        Name: data.Name,
                        Type: data.Type, 	
                        ParameterName: data.ParameterName,
                        FriendlyName:data.FriendlyName,
                        SequenceNo: data.SequenceNo,
                        ProjectOption: JSON.parse(data.ProjectNames),
                        TableID: data.TableID,
                        TableOption: JSON.parse(data.TableNames)
                    })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
                this.setState({ IsloadData: false })
            })
    }

    wsAddEditField(ProjectID, TableID, ID, Name, Type, ParameterName, FriendlyName, SequenceNo) {
        this.setState({ IsLoading: true })
        APIController.AddEditField(ProjectID, TableID, ID, Name, Type, ParameterName, FriendlyName, SequenceNo)
            .then((response) => {
                this.setState({ IsLoading: false })
                if (response.error == null) {
                    this.props.handlebind()
                    this.props.handleNotification(true, response.object.message,)
                    this.props.onClose()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    handleNotification(val, msg, type) {
        this.setState({ ShowNotification: val, Message: msg, ToastType: type })
    }

    render() {
        const { ProjectID, ProjectOption, TableID, TableOption, Name, Type, ParameterName, FriendlyName, SequenceNo, IsValid, IsLoading, IsloadData } = this.state;
        return (
            IsloadData ? <CircularProgress /> :
                <Grid container lg={12} justify='center' >
                    <Grid container justify="space-between">
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <DropDown name={'ProjectID'} value={ProjectID} id={'Project Name'} onChange={this.handleChange} optionText={ProjectOption} helper={'Select Project Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <DropDown name={'TableID'} value={TableID} id={'Table Name'} onChange={this.handleChange} optionText={TableOption} helper={'Select Table Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'Name'} id={'Name'} onChange={this.handleChange} value={Name} helper={'Please Enter Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'Type'} id={'Type'} onChange={this.handleChange} value={Type} helper={'Please Enter Type'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'ParameterName'} id={'Parameter Name'} onChange={this.handleChange} value={ParameterName} helper={'Please Enter Parameter Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'FriendlyName'} id={'Friendly Name'} onChange={this.handleChange} value={FriendlyName} helper={'Please Enter Friendly Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'SequenceNo'} id={'Sequence No'} onChange={this.handleChange} value={SequenceNo} helper={'Please Enter Sequence No'} IsValid={IsValid} />
                        </Grid>
                    </Grid>




                    <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <DialogActions>
                            <CommonButton IsLoading={IsLoading} onClick={() => this.handleValidate()} color="primary">{'Save'}</CommonButton>
                            <CommonButton onClick={() => this.props.onClose()} color="secondary">{'Close'}</CommonButton>
                        </DialogActions>
                    </Grid>
                </Grid>

        );
    }
}
export default GetFieldsForm;
