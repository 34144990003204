import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // If using React Router
import './navbar.css'; // Import your CSS file for styling
import logo from '../../autocodegpt-logo.png'

function Navbar() {
  const [activeLink, setActiveLink] = useState('home');

  const handleSetActive = (link) => {
    setActiveLink(link);
  };

  return (
    <nav className="navbar">
      <div style={{ display: 'flex',marginTop:'10px',marginRight:'20px',marginLeft:'20px',alignItems:'center' }}>
        <div style={{width:'20%'}}>
          <img style={{width:'100%',}} src={logo} alt='logo' />
        </div>

        <ul className="navbar-menu" style={{width:'80%'}}>
          <li className={activeLink === 'project' ? 'active' : ''}>
            <Link to="/" onClick={() => handleSetActive('project')}>
              Projects
            </Link>
          </li>
          <li className={activeLink === 'controllerlist' ? 'active' : ''}>
            <Link to="/controllerlist" onClick={() => handleSetActive('controllerlist')}>
              Controllers
            </Link>
          </li>
          <li className={activeLink === 'storeproducered' ? 'active' : ''}>
            <Link to="/storedprocedures" onClick={() => handleSetActive('storeproducered')}>
              Stored Procedures
            </Link>
          </li>
          <li className={activeLink === 'tablelist' ? 'active' : ''}>
            <Link to="/tablelist" onClick={() => handleSetActive('tablelist')}>
              Tables
            </Link>
          </li>
          <li className={activeLink === 'GetFieldsList' ? 'active' : ''}>
            <Link to="/getfieldslist" onClick={() => handleSetActive('GetFieldsList')}>
              Fields
            </Link>
          </li>
          <li className={activeLink === 'myapislist' ? 'active' : ''}>
            <Link to="/myapislist" onClick={() => handleSetActive('myapislist')}>
              APIs
            </Link>
          </li>
          <li className={activeLink === 'screenslist' ? 'active' : ''}>
            <Link to="/screenslist" onClick={() => handleSetActive('screenslist')}>
              Screens
            </Link>
          </li>

          {/* <li className={activeLink === 'configuretables' ? 'active' : ''}>
          <Link to="/configuretables" onClick={() => handleSetActive('configuretables')}>
          ConfigureTables
          </Link>
        </li> */}


        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
