const Location = window.location.toString();

export const URLs = {
    base:"https://autocodegpt.api.thestaging.cc/",
      

    // Auth
    Gettoken: '/token',
    RegisterBrowserNotificationToken: 'API/Users/RegisterBrowserNotificationToken',
    SendNotification: 'API/Users/SendNotification',

    //  Dashboard
    GetMyDashboard: 'API/Dashboard/GetMyDashboard',
    SearchDashboard: 'API/Dashboard/SearchDashboard',

    // EditProfile
    GetMyProfile: 'API/Users/GetMyProfile',
    EditProfile: 'API/Users/EditProfile',
    ForgotPassword: 'API/Users/ForgotPassword',
    ChangePassword: 'API/Users/ChangePassword',
    SetPassword: 'API/Users/SetPassword',
    VerifyOTP: 'API/Users/VerifyOTP',
    ResendOTP: 'API/Users/ResendOTP',
    GetNotifications: 'API/Users/GetNotifications',
    VerifyToken: 'API/Users/VerifyToken',
    GetPortalDashboard: 'API/Users/GetPortalDashboard',
    AllocateKeys: '/API/Keys/AllocateKeys',
    RevokeKeys: '/API/Keys/RevokeKeys',

    //  Orders
    OrderDetails: 'API/Order/OrderDetails',
}