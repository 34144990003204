
import React from 'react';
import TextField from "../../Camponets/TextField/TextField";
import CommonButton from "../../Camponets/Button/CommonButton";
import CommonSwitch from "../../Camponets/Switch/CommonSwitch";
import Grid from '@material-ui/core/Grid';
import * as APIController from '../../APIs/APIController.js';
import { CircularProgress } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions';
import DropDown from "../../Camponets/Select/DropDown";

class ConfigureTables extends React.Component {
    state = {
        ProjectName: '',
        TableName: '',
        Controller: '',
        StoredProcedure: '',
        Singular: '',
        Plural: '',
        ControllerID: '',
        ProjectNameOption: [],
        ConfigureTablesOption: [],
        ControllersOption: [],
        Tables: [],
        touched: false,
        IsValid: '',
        option: [],
        IsLoading: false,
        IsloadData: false
    }

    componentDidMount() {

        this.wsGetProjectForDropdown()

    }

    wsGetProjectForDropdown() {
        APIController.GetProjectForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ProjectNameOption: response.object.data });

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message);
                }
            });
    };

    wsGetConfiguredTables(ProjectId) {
        APIController.GetConfiguredTables(ProjectId)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ConfigureTablesOption: response.object.data.Tables, ControllersOption: response.object.data.Controllers });

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message);
                }
            });
    };
    wsSaveConfiguredTables(ProjectID, Tables) {
        APIController.SaveConfiguredTables(ProjectID, Tables)
            .then((response) => {
                if (response.error == null) {
                    this.props.handlebind()
                    this.props.handleNotification(true, response.object.message,)
                    this.props.onClose()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message,'error');
                }
            });
    };


    handleChange = (e, index) => {
        if (e.target.name == "ProjectName") {
            const projectID = e.target.value
            this.wsGetConfiguredTables(projectID)
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        } else {
            const newData = [...this.state.ConfigureTablesOption];
            newData[index][e.target.name] = e.target.value;
            this.setState({ ConfigureTablesOption: newData })

            // this.setState({ [e.target.value]: e.target.value });
            // this.setState({ [e.target.name]: e.target.value });
        }

    }

    handleValidate() {

        if (this.state.ProjectName == '') {
            this.setState({ IsValid: true, })
        }
        else {

            //Add Edit Api Here
            this.wsSaveConfiguredTables(this.state.ProjectName, this.state.ConfigureTablesOption,)
        }

    }

    render() {
        const { ProjectName, TableName, ConfigureTablesOption, ControllerID, ControllersOption, Controller, StoredProcedure, Singular, Plural, ControllerOption, ProjectNameOption, StoredProcedureOption, IsValid, IsLoading, IsloadData, option } = this.state;
        if (IsloadData)
            return <Grid container lg={12} justify='center' style={{ width: 150 }}>
                <CircularProgress />
            </Grid>
        else return (
            <div style={{margin:"20px"}}>
                <form onSubmit={(e) => { e.preventDefault(); this.handleValidate(); }}>
                    <Grid container lg={12} >
                        <Grid container xl={12} md={12} sm={6} xs={12}>
                            <DropDown name={'ProjectName'} value={ProjectName} id={'Project Name'} onChange={this.handleChange} optionText={ProjectNameOption} helper={'Select Project Name'} IsValid={IsValid} />
                        </Grid>
                        {/* {ConfigureTablesOption.map((item, ind) => {
                        return ( */}

                        {ConfigureTablesOption.map((ele, index) => {
                            return (
                                <Grid container xl={12} xs={12} style={{ borderBottom: '2px solid black' }}>
                                    <Grid container xl={12} md={6} sm={6} xs={12}>
                                        <p>{ele.Name}</p>
                                    </Grid>
                                    <Grid container justify="space-between" >
                                        <Grid container lg={3} xl={3} md={3} sm={12} xs={12}>
                                            <DropDown name={'ControllerID'} value={ele.ControllerID == null ? "" : ele.ControllerID} id={'Controller Name'} onChange={(e) => this.handleChange(e, index)} optionText={ControllersOption} helper={'Select Controller Name'} IsValid={IsValid} />
                                        </Grid>
                                        <Grid container lg={3} xl={3} md={3} sm={12} xs={12}>
                                            <TextField name={`PluralName`} value={ele.PluralName} id={`Plural Name`} onChange={(e) => this.handleChange(e, index)} helper={`Please Enter Plural Name`} IsValid={IsValid} />

                                        </Grid>
                                        <Grid container lg={3} xl={3} md={3} sm={12} xs={12}>
                                            <TextField name={`SingularName`} value={ele.SingularName} id={`Singular Name`} onChange={(e) => this.handleChange(e, index)} helper={`Please Enter Singular Name`} IsValid={IsValid} />
                                        </Grid>
                                        <Grid container lg={3} xl={3} md={3} sm={12} xs={12}>
                                            <TextField name={`StoredProcedureName`} value={ele.StoredProcedureName} id={`Stored Procedure Name`} onChange={(e) => this.handleChange(e, index)} helper={`Please Enter Stored Procedure Name`} IsValid={IsValid} />
                                        </Grid>
                                    </Grid>

                                </Grid>
                            )
                        })}
                        {/* )
                    })} */}

                        {/* <Grid container xl={6} md={6} sm={6} xs={6}>
                        <DropDown name={'Controller'} value={Controller} id={'Select Controller'} onChange={this.handleChange} optionText={ControllerOption} helper={'Select Controller'} IsValid={IsValid} />
                    </Grid>
                    <Grid container xl={6} md={6} sm={6} xs={6}>
                        <DropDown name={'StoredProcedure'} value={StoredProcedure} id={'Select StoredProcedure'} onChange={this.handleChange} optionText={StoredProcedureOption} helper={'Select StoredProcedure'} IsValid={IsValid} />
                    </Grid>
                    <Grid container xl={6} md={6} sm={6} xs={6}>
                        <TextField name={'Singular'} value={Singular} id={'Singular'} onChange={this.handleChange} helper={'Please Enter Singular'} IsValid={IsValid} />
                    </Grid>
                    <Grid container xl={6} md={6} sm={6} xs={6}>
                        <TextField name={'Plural'} value={Plural} id={'Plural'} onChange={this.handleChange} helper={'Please Enter Plural'} IsValid={IsValid} />
                    </Grid> */}
                        {/* <CommonSwitch name={'IsActive'} value={IsActive} id={'IsActive'} onChange={(e) => this.setState({ IsActive: e.target.checked })} label={'Is Active?'} checked={IsActive} /> */}

                        <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <DialogActions>
                                <CommonButton IsLoading={IsLoading} type='submit' color="primary">{'Save'}</CommonButton>
                                <CommonButton onClick={() => this.props.onClose()} color="secondary">{'Close'}</CommonButton>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}
export default ConfigureTables;
