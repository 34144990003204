import { Button, IconButton, TablePagination, } from '@material-ui/core';

import React, { createContext, useEffect, useRef, useState } from 'react';
import { AiOutlineMail, AiTwotoneBell } from 'react-icons/ai';
import { BiArrowBack, BiDownArrowAlt, BiFilterAlt, BiSolidMessageSquareAdd, BiSolidMessageSquareEdit, BiUpArrowAlt } from 'react-icons/bi';
import { BsEye, BsTelephone } from 'react-icons/bs';
import { FaThumbsUp } from 'react-icons/fa';
import { HiDocumentDownload } from 'react-icons/hi';
import { IoNewspaper, IoNewspaperOutline } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import { MdDeleteSweep, MdDone, MdDoneAll, MdDownload, MdDownloadDone, MdFileUpload } from 'react-icons/md';
import { TfiReload } from 'react-icons/tfi';
import { useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AppConstant, TableColor, chipData, } from "../../Camponets/AppConstant";
import './datagrid.css';


// import moment from 'moment';
// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import 'react-daterange-picker/dist/css/react-calendar.css'; // For some basic styling. (OPTIONAL)

import { RxDotFilled } from 'react-icons/rx';
import * as APIController from '../../APIs/APIController.js';


import useOutsideClick from './OuterClick';


export default function DataGrid(props) {
	const { tableStyle, button, tabs, ShopName, setStartsDate, setEndsDate, tableHeader, IsFilter, MaxRows, gridData, shortingIcon, sorting, searchingInput, checked, page, pageSize, goBack, handleTopBtnClick,
		handleActionBtnClick, handleChangeRowsPerPage, navigateToNextScreen, handleChangePage, applySorting, handleSearch, handleKeypress, handleSelect, handleGotoPage, handleSubmitGotoPage } = props

	const [handleStartDate, sethandleStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
	const [handleEndDate, sethandleEndDate] = useState(new Date());
	const [Open, setOpen] = useState(false);
	const [expanded, setExpanded] = useState(0);
	const [expanded2, setExpanded2] = useState(1);
	const [TabView, setTabView] = useState("");
	const [gridData2, setGridData2] = useState([]);

	const ref = useRef();
	useOutsideClick(ref, () => {
		setOpen(false)
	});

	let history = useLocation();
	// useEffect(() => {
	// 	let UserRoleID = localStorage.getItem("localStorageUserRoleID")
	// 	UserType.filter((item) => {
	// 		if (item.ID.toLowerCase() == UserRoleID) {
	// 			setTabView(item.No)
	// 		}
	// 	})
	// 	rolesAndRightsGetNames(rolesAndRights(), PermissionList.List) ? LogOut() : null
	// }, []);


	// const handleSelectDate = (data) => {
	// 	sethandleStartDate(new Date(data.startDate))
	// 	sethandleEndDate(new Date(data.endDate))
	// 	if (handleEndDate != '') {
	// 		let TempDates = []
	// 		TempDates.push({ 'StartDate': data.startDate })
	// 		TempDates.push({ 'EndDate': data.endDate })
	// 		localStorage.setItem('DashboardDate', JSON.stringify(TempDates))
	// 		const dateStart = new Date(TempDates[0].StartDate);
	// 		const dateEnd = new Date(TempDates[1].EndDate);
	// 		const startFormet = moment(dateStart, "YYYY/MM/DD");
	// 		const endFormet = moment(dateEnd, "YYYY/MM/DD");
	// 		setStartsDate(startFormet.format('MM/DD/YYYY'));
	// 		setEndsDate(endFormet.format('MM/DD/YYYY'));
	// 	};
	// };

	// const LogOut = () => {
	// 	APIController.RegisterBrowserNotificationToken('').then((res) => { })
	// 	window.history.replaceState(null, null, "/");
	// 	authService.removeAuthData()
	// 	localStorage.clear()
	// 	window.location.replace("/")
	// 	window.location.reload()
	// }

	return (
		<>
			<div className='product-main' >
				<div className='product-head' >
					<div style={{ display: "flex", alignItems: 'center' }}>
						{goBack && <IconButton onClick={() => history.goBack()} sx={{ color: TableColor.main }}><BiArrowBack style={{ color: TableColor.main }} /></IconButton>}
						{ShopName == "" || ShopName == undefined ? <h2 >{tableHeader}</h2> : <h2>{tableHeader} Of {ShopName}</h2>}
					</div>
					{tabs && <div className='product-button-group'>
						{button.map((item, ind) => {
							if (item.type == "icon" && item.id == 4) {
								return <div> <IconButton id={item.name} className='icon' onClick={(e) => handleTopBtnClick(e, item.id)} color={item.color} variant="contained" ><BiSolidMessageSquareAdd /></IconButton></div>
							} else if (item.type == "icon" && item.id == 5) {
								return <div> <IconButton id={item.name} className='icon' onClick={(e) => handleTopBtnClick(e, item.id)} color={item.color} variant="contained" ><IoMdAdd /></IconButton></div>
							} else if (item.type == "icon" && item.id == 6) {
								return <div> <IconButton id={item.name} className='icon' onClick={(e) => handleTopBtnClick(e, item.id)} color={item.color} variant="contained" ><TfiReload /></IconButton></div>
							} else if (item.type == "icon" && item.id == 11) {
								return <div> <IconButton id={item.name} className='icon' onClick={(e) => handleTopBtnClick(e, item.id)} color={item.color} variant="contained" ><IoMdAdd /></IconButton></div>
							}
						})}
					</div>}
					{IsFilter && !tabs && <div className='product-button-group'>
						{button.map((item, ind) => {
							if (item.type == "text") {
								return <Button className='product-button' onClick={(e) => handleTopBtnClick(e, item.id)} style={{ color: TableColor.buttonFontColor, backgroundColor: TableColor.main }} variant="contained" >{item.name}</Button>
							} else if (item.type == "icon" && item.id == 4) {
								return <div> <IconButton id={item.name} className='icon' onClick={(e) => handleTopBtnClick(e, item.id)} color={item.color} variant="contained" ><BiSolidMessageSquareAdd /></IconButton></div>
							} else if (item.type == "icon" && item.id == 5) {
								return <div> <IconButton id={item.name} className='icon' onClick={(e) => handleTopBtnClick(e, item.id)} color={item.color} variant="contained" ><IoMdAdd /></IconButton></div>
							} else if (item.type == "icon" && item.id == 7) {
								return <div> <IconButton id={item.name} className='icon' onClick={(e) => handleTopBtnClick(e, item.id)} color={item.color} variant="contained" ><BsEye /></IconButton></div>
							};
						})}
					</div>}
					{IsFilter && button.map((item) => {
						return <ReactTooltip
							place={'bottom'}
							anchorSelect={`#${item.name}`}
							content={item.name}>
						</ReactTooltip>
					})
					}
				</div>

				<div className="product-table" style={{ maxHeight: IsFilter ? "" : "300px", overflow: 'auto' }} >
					<table id='product-table' >
						{/* table title */}
						<tr>
							{tableStyle.map((ele, ind) => {
								if (IsFilter) {
									if (ele.icon == true) {
										return <th style={{ position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && AppConstant.StickyColor, textAlign: ele.position, textAlign: ele.position, width: ele.width }}><IconButton><AiTwotoneBell /></IconButton></th>
									} else if (ele.icon == false) {
										return <th style={{ position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && AppConstant.StickyColor, textAlign: ele.position, textAlign: ele.position, width: ele.width }}><IconButton><FaThumbsUp /></IconButton></th>
									} else {
										return <th key={ind} style={{
											position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && AppConstant.StickyColor, textAlign: ele.position, textAlign: ele.position, width: ele.width
										}} onClick={() => applySorting(ele.fieldName, !sorting.ascending)}>{ele.headerName}{sorting.ascending ? shortingIcon && sorting.field === `${ele.fieldName}` && <span><BiUpArrowAlt /></span> : shortingIcon && sorting.field === `${ele.fieldName}` && <span><BiDownArrowAlt /></span>}</th>
									}
								} else
									return <th style={{ position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && AppConstant.StickyColor, textAlign: ele.position, textAlign: ele.position, width: ele.width }}>{ele.headerName}</th>
							})}
						</tr>
						{/* Search field */}
						{IsFilter && <tr className='product-searchbar'>
							{tableStyle.map((item, ind) => {
								const arrName = item.fieldName
								if (item.actionType == 1) {
									return <th key={ind} style={{ position: `${item.sticky}`, left: "0", backgroundColor: item.sticky == 'sticky' && "#f5f5f5" }}> <div style={{ textAlign: item.position }}><IconButton size='small'><BiFilterAlt /></IconButton></div></th>
								} else if (item.actionType == 2) {
									return <th key={ind} style={{ width: `${item.width}`, position: `${item.sticky}`, left: "0", backgroundColor: item.sticky == 'sticky' && "#f5f5f5", }}><input type='text' style={{ cursor: item.disabaleSearch ? "not-allowed" : "pointer" }} name={arrName} value={item.disabaleSearch ? "" : searchingInput.arrName} onChange={(e) => handleSearch(arrName, e)} onKeyDown={(e) => handleKeypress(e, arrName)} /></th>
								} else if (item.actionType == 3) {
									return <th key={ind} style={{ position: `${item.sticky}`, left: "0", backgroundColor: item.sticky == 'sticky' && "#f5f5f5", }}><select style={{ margin: "0px" }} headerName={arrName} value={searchingInput.arrName} onChange={(e) => handleSelect(arrName, e)} id="select" >
										{item.selectOption.map((ele, index) => {
											return <option style={{ height: "35px" }} key={index} name={arrName} selected={ele.selected == 1 ? "selected" : ''} value={ele.value}>{ele.name}</option>
										})}
									</select></th>
								}
							})}
						</tr>}

						{/* table data */}
						{gridData.map((val, key) => {
							return (
								<tr className='table-data' key={key}>
									{tableStyle.map((ele, ind) => {

										if (ele.headerName == "Action") {
											return <td key={ind} >
												<div className='table-action-btn' style={{ textAlign: "center" }}>
													{ele.actionButton.map((item) => {
														switch (item.name) {
															case "Edit":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><BiSolidMessageSquareEdit style={{ fontSize: `${item.size}` }} /></IconButton>

															case "Delete":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><MdDeleteSweep style={{ fontSize: `${item.size}` }} /></IconButton>

															case "Upload":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><MdFileUpload style={{ fontSize: `${item.size}` }} /></IconButton>

															case "Download":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><MdDownload style={{ fontSize: `${item.size}` }} /></IconButton>

															case "Add":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><BiSolidMessageSquareAdd style={{ fontSize: `${item.size}` }} /></IconButton>
															case "Apicontroller":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><IoNewspaper style={{ fontSize: `${item.size}` }} /></IconButton>
															case "Caller":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><IoNewspaperOutline  style={{ fontSize: `${item.size}` }} /></IconButton>
															case "APIParameters":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><MdDone style={{ fontSize: `${item.size}` }} /></IconButton>
															case "API":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><MdDoneAll style={{ fontSize: `${item.size}` }} /></IconButton>
															case "Model":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><MdDownloadDone style={{ fontSize: `${item.size}` }} /></IconButton>

															case "View":
																return <IconButton id={item.name} className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><BsEye style={{ fontSize: `${item.size}` }} /></IconButton>

															case "Status":
																return <a data-tooltip-content={item.tooltip == true ? "See Measuring Point Reading" : `Description : ${val.Description}`} data-tooltip-id="description"><IconButton className='actionButton' onClick={() => handleActionBtnClick(val, key, item.id)} color={`${item.color}`}><RxDotFilled style={{ VerticalAlign: 'center', fontSize: '20px', color: val.ScheduleTypeID == '1' ? "red" : val.ScheduleTypeID == '2' ? "blue" : val.ScheduleTypeID == '3' ? "green" : "" }} /></IconButton></a>
														}
													})}
														{ele.actionButton.map((item) => {
														return(	<ReactTooltip
															anchorSelect={`#${item.name}`}
															place="bottom"
															content={ item.name}
														/>)})}
												</div>
												{/* <ReactTooltip
													place={'bottom'}
													data-tooltip-wrapper={true}
													multiline={true} id="description">
												</ReactTooltip> */}
											</td>
										} else if (ele.actionType == 2) {

											if (ele.showPhoneIcon === true) {
												return <td style={{ width: ele.width, position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && "#f5f5f5", textAlign: ele.position }}>
													<div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: ele.width, justifyContent: ele.position, color: 'black' }}>{val[ele.fieldName] === "" ? "" : <BsTelephone style={{ marginRight: '10px' }} />} {val[ele.fieldName]}</div>
												</td>
											} else if (ele.showEmailIcon === true) {
												return <td style={{ width: ele.width, position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && "#f5f5f5", textAlign: ele.position }}>
													<div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: ele.width, justifyContent: ele.position, aligneles: "center", color: 'black' }}>{val[ele.fieldName] === "" ? "" : <AiOutlineMail style={{ marginRight: '10px' }} />} {val[ele.fieldName]}</div>
												</td>
											} else if (ele.showEyeIcon === true) {
												return <td style={{ width: ele.width, position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && "#f5f5f5", textAlign: ele.position }}>
													<div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: ele.width, justifyContent: ele.position, aligneles: "center", color: 'black' }}>{val[ele.fieldName] === "" ? "" : <BsEye style={{ marginRight: '10px' }} />} {val[ele.fieldName]}</div>
												</td>
											} else if (ele.isURL === true) {
												return <td style={{ width: ele.width, position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && "#f5f5f5", textAlign: ele.position }}>
													<div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: ele.width, justifyContent: "center", color: 'blue', cursor: 'pointer' }} onClick={() => navigateToNextScreen(val)}>{val[ele.fieldName]}</div>
												</td>
											} else if (ele.Tip === true) {
												return <td style={{ width: ele.width, position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && "#f5f5f5", textAlign: ele.position }}>
													<a data-tooltip-content={ele.tooltip == true ? "See Measuring Point Reading" : `Equipment Name : ${val.EquipmentNameFull}`} data-tooltip-id="eqipment" >
														<div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: ele.width, justifyContent: "center", color: 'black', cursor: 'pointer' }} onClick={() => navigateToNextScreen(val)}>{val[ele.fieldName]}</div></a>
												</td>
											}
											else {
												return <td style={{ width: ele.width, position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && "#f5f5f5", textAlign: ele.position }}>
													<div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: ele.width, justifyContent: "center", color: 'black' }} >{val[ele.fieldName]}</div>
												</td>
											}
										}
										else if (ele.actionType == 3) {
											if (ele.showAsTag) {
												return <td key={ind} style={{ width: ele.width, position: ele.sticky, left: "0", backgroundColor: ele.sticky == 'sticky' && "#f5f5f5", textAlign: "center" }}>
													{
														chipData.map((item, ind) => {
															if (val[ele.fieldName] == item.value) {
																return <div key={ind} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: ele.width, justifyContent: ele.position, textAlign: ele.position, margin: "0%", display: "flex" }}><p className="active-lable" style={{ padding: item.name == "Acknowledged" ? "1px 0px" : "1px 14px", color: item.color, backgroundColor: item.backgroundColor, width: item.width }}>{item.value === "" || item.value === null ? "N/A" : item.name}</p></div>
															}
														})
													}
												</td>
											}
										}
									})}
								</tr>
							)
						})}
						<ReactTooltip
							place={'bottom'}
							data-tooltip-wrapper={true}
							multiline={true} id="eqipment">
						</ReactTooltip>
					</table>
				</div>
				{IsFilter && <div style={{ display: "flex", justifyContent: "end", alignItems: "center", padding: "4px 20px" }}>
					<TablePagination
						rowsPerPageOptions={AppConstant.Pagination}
						component="div"
						count={MaxRows}
						rowsPerPage={pageSize}
						page={page}
						onChangePage={handleChangePage}
						showFirstButton
						showLastButton
						onChangeRowsPerPage={handleChangeRowsPerPage} />
					<div style={{ color: '#00000099', fontSize: '13px', fontStyle: 'bold' }}>Page: <input style={{ width: "50px", border: '1px solid #cdcdcd', borderRadius: "5px" }} type="number" onChange={(e) => handleGotoPage(e)} onKeyDown={handleSubmitGotoPage} /> Of {Math.ceil(parseInt(MaxRows) / parseInt(pageSize))}</div>
				</div>}
			</div>
		</>
	)
}

