import { CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import * as APIController from '../../APIs/APIController.js';
import CommonButton from "../../Camponets/Button/CommonButton.js";
import DropDown from "../../Camponets/Select/DropDown.js";
import TextField from "../../Camponets/TextField/TextField.js";
import CommonSwitch from "../../Camponets/Switch/CommonSwitch.js";
import { useLocation } from 'react-router-dom';
import CheckBox from '../../Camponets/CheckBox/CheckBox.js';

class ScreensForm extends React.Component {
    state = {
        ID: '',
        ProjectID: '',
        TableID: '',
        PageName: "",
        EditID: '0',
        Label: '',
        Name: '',
        Value: '',
        FieldID: '',
        DefaultValue: '',
        DropdownOptions: '',
        ButtonType: '',
        ButtonTypeOption: [],
        TypeName: "",
        TypeID:'',
        MaxLength: '',
        SequenceNo: '',
        SearchSuggestion: '',
        RadioButtonOptions: '',
        CheckBoxOptions: '',
        ControlID: '',
        IsMandatory: true,
        IsDisabled: true,
        IsFullWidth: true,
        Close: false,
        TypeNameOption: [{ 'Name': "input", 'ID': 'input' }, { 'Name': "dropdown", 'ID': 'dropdown' }, { 'Name': "radio", 'ID': 'radio' }, { 'Name': "number", 'ID': 'number' }, { 'Name': "pincode", 'ID': 'pincode' },
        { 'Name': "time", 'ID': 'time' }, { 'Name': "datetime-local", 'ID': 'datetime-local' }, { 'Name': "switch", 'ID': 'switch' }, { 'Name': "search", 'ID': 'search' }, { 'Name': "checkbox", 'ID': 'checkbox' }, { 'Name': 'singalcheckbox', 'ID': 'singalcheckbox' }],
        FieldData: [],
        TypeIDOption: [{ 'Name': "Add/Edit", 'ID': '1' }, { 'Name': "Listing", 'ID': '4' },],
        ApiOption: [],
        AllFieldData: [],
        ProjectOption: [],
        TableOption: [],
        IsValid: false,
        IsLoading: false,
        IsloadData: false
    }

    componentDidMount() {
        this.props.EditID != 0 && this.wsGetScreenByID(this.props.EditID)

        this.wsGetProjectForDropdown()
    }

    wsGetProjectForDropdown() {
        APIController.GetProjectForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ProjectOption: response.object.data })
                    this.wsGetTableForDropdown()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    wsGetTableForDropdown() {
        APIController.GetTableForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ TableOption: response.object.data })

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }

    wsGetConfiguredFields(ProjectID, TableID, TableName, TypeID, APIID) {
        APIController.GetConfiguredFields(ProjectID, TableID, TableName, TypeID, APIID)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ FieldData: response.object.data, AllFieldData: response.object.data });
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message);
                }
            });
    };
    wsGetMyAPIForDropdown(ProjectID, TableID,) {
        APIController.GetMyAPIForDropdown(ProjectID, TableID,)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ApiOption: response.object.data, });

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message);
                }
            });
    };

    wsAddEditScreen(ID, ProjectID, TableID, TableName, TypeID,ApiID, JSON) {
        APIController.AddEditScreen(ID, ProjectID, TableID, TableName, TypeID,ApiID, JSON)
            .then((response) => {
                this.setState({ IsLoading: false })
                if (response.error == null) {
                    this.props.handlebind()
                    this.props.handleNotification(true, response.object.message,)
                    { this.state.Close && this.props.onClose() }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    };
    wsGetScreenByID(ID,) {
        APIController.GetScreenByID(ID,)
            .then((response) => {
                this.setState({ IsLoading: false })
                if (response.error == null) {
                    this.setState({
                        EditID: ID,
                        ProjectID: response.object.data.ProjectID,
                        TableID: response.object.data.TableID,
                       
                        TypeID: response.object.data.TypeID,
                        PageName: response.object.data.APIID,
                        ApiOption:response.object.data.APIName,
                        AllFieldData: response.object.data.Fields == ""?[]:response.object.data.Fields,
                        FieldData: response.object.data.Fields == ""?[]:response.object.data.Fields,
                    })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    };

    handleChange = (e) => {
        if (e.target.name == "TableID") {
            const tablename = this.state.TableOption.filter((item, ind) => {
                if (e.target.value == item.ID) {
                    return item.Name
                }
            })
            this.setState({ TableName: tablename[0].Name })
            this.wsGetMyAPIForDropdown(this.state.ProjectID, tablename[0].ID,)
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        } else if (e.target.name == "PageName") {
            const ApiName = this.state.ApiOption.filter((item, ind) => {
                if (e.target.value == item.ID) {
                    return item.Name
                }
            })
            
            this.wsGetConfiguredFields(this.state.ProjectID, this.state.TableID, this.state.TableName, this.state.TypeID, ApiName[0].ID)
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        } else {
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        }

    }
    handleChangeField = (e, event, id, ind) => {

        const newData = [...this.state.AllFieldData];
        if (e) {
            newData[ind][e.target.name] = e.target.value;
        } else if (event) {
            newData[ind][event.target.name] = event.target.checked ? 1 : 0;

        }
        this.setState({ AllFieldData: newData });

    }


    handleValidate() {
        if (this.state.ProjectID == '') {
            this.setState({ IsValid: true, })
        } else if (this.state.TableID == '') {
            this.setState({ IsValid: true, })
        } else {
            const newJson = this.state.AllFieldData.map((item, ind) => {
                return [item].filter((ele, ind) => {
                    if (ele.IsChecked == 1) {
                        return ele
                    }
                })
            })
            const allJson = newJson.filter((item) => {
                if (item != '') {
                    return item
                }
            })

            const mergedArray = [].concat(...allJson);
            this.wsAddEditScreen(this.state.EditID, this.state.ProjectID, this.state.TableID, this.state.TableName, this.state.TypeID,this.state.PageName, mergedArray,)
            this.setState({ Close: true })

        }
    }




    handleNotification(val, msg, type) {
        this.setState({ ShowNotification: val, Message: msg, ToastType: type })
    }

    render() {


        const { ProjectID, ProjectOption, TableID, TableOption, PageName, TypeID, FieldData, AllFieldData, TypeNameOption,
            TypeName, IsValid, IsLoading, IsloadData, TypeIDOption, ApiOption } = this.state;
        return (
            IsloadData ? <CircularProgress /> :
                <Grid container lg={12} justify='center' >
                    <Grid container xl={3} md={3} sm={3} xs={3}>
                        <DropDown name={'ProjectID'} value={ProjectID} id={'Project Name'} onChange={this.handleChange} optionText={ProjectOption} helper={'Select Project Name'} IsValid={IsValid} />
                    </Grid>
                    <Grid container xl={3} md={3} sm={3} xs={3}>
                        <DropDown name={'TableID'} value={TableID} id={'Table Name'} onChange={this.handleChange} optionText={TableOption} helper={'Select Table Name'} IsValid={IsValid} />
                    </Grid>
                    <Grid container xl={3} md={3} sm={3} xs={3}>
                        <DropDown name={'TypeID'} value={TypeID} id={'TypeID'} onChange={this.handleChange} optionText={TypeIDOption} helper={'Select Type'} IsValid={IsValid} />
                        {/* <TextField name={'TypeID'} id={'Type'} onChange={this.handleChange} value={TypeID} helper={'Please Enter TypeS'} IsValid={IsValid} /> */}
                    </Grid>
                    <Grid container xl={3} md={3} sm={3} xs={3}>
                        <DropDown name={'PageName'} value={PageName} id={'Api Name'} onChange={this.handleChange} optionText={ApiOption} helper={'Select Api Name'} IsValid={IsValid} />
                        {/* <TextField name={'PageName'} id={'Page Name'} onChange={this.handleChange} value={PageName} helper={'Please Enter Page Name'} IsValid={IsValid} /> */}
                    </Grid>
                    {/* <Grid container>
                        <TextField name={'TypeID'} id={'TypeID'} onChange={this.handleChange} value={TypeID} helper={'Please Enter TypeID'} IsValid={IsValid} />
                    </Grid> */}

                    {FieldData.map((item, ind) => {
                        console.log('TypeName', TypeName);
                        return (
                            <div key={ind}>
                                <div style={{ display: 'flex', maxWidth: '1200px', justifyContent: 'flex-start', gap: '10px', paddingBottom: '20px', borderBottom: '2px solid black', overflow: 'auto' }} >
                                    <div style={{ width: '200px' }}>
                                        <CommonSwitch name={'IsChecked'} value={AllFieldData[ind].IsChecked ? 1 : 0} id={'IsChecked'} onChange={(event) => this.handleChangeField('', event, item.ID, ind)} label={'IsChecked'} checked={AllFieldData[ind].IsChecked == 1 ? true : false} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: 'flex-start', gap: '5px', alignItems: 'center', }}>
                                        <div style={{ width: '200px' }}>
                                            <DropDown name={'Type'} value={AllFieldData[ind].Type} id={'Type Name'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} optionText={TypeNameOption} helper={'Select Type Name'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'Label'} id={'Label'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].Label} helper={'Please Enter Label'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'Name'} id={'Name'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].Name} helper={'Please Enter Name'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <CommonSwitch name={'IsMandatory'} value={AllFieldData[ind].IsMandatory} id={'IsMandatory'} onChange={(event) => this.handleChangeField('', event, item.ID, ind)} label={'IsMandatory'} checked={AllFieldData[ind].IsMandatory ? true : false} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'MaxLength'} id={'MaxLength'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].MaxLength} helper={'Please Enter MaxLength'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'DropdownOptions'} id={'DropdownOptions'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].DropdownOptions} helper={'Please Enter Dropdown Options'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'DefaultValue'} id={'DefaultValue'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].DefaultValue} helper={'Please Enter Default Value'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'SequenceNo'} id={'SequenceNo'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].SequenceNo} helper={'Please Enter Sequence No'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'Value'} id={'Value'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].Value} helper={'Please Enter Value'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'RadioButtonOptions'} id={'RadioButtonOptions'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].RadioButtonOptions} helper={'Please Enter RadioButton Options'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <CommonSwitch name={'IsFullWidth'} value={AllFieldData[ind].IsFullWidth} id={'IsFullWidth'} onChange={(event) => this.handleChangeField('', event, item.ID, ind)} label={'IsFullWidth'} checked={AllFieldData[ind].IsFullWidth == undefined ? false : true} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'ControlID'} id={'ControlID'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].ControlID} helper={'Please Enter ControlID'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'SearchSuggestion'} id={'SearchSuggestion'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].SearchSuggestion} helper={'Please Enter Search Suggestion'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'CheckBoxOptions'} id={'CheckBoxOptions'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].CheckBoxOptions} helper={'Please Enter CheckBox Options'} IsValid={IsValid} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <CommonSwitch name={'IsDisabled'} value={AllFieldData[ind].IsDisabled} id={'IsDisabled'} onChange={(event) => this.handleChangeField('', event, item.ID, ind)} label={'IsDisabled'} checked={AllFieldData[ind].IsDisabled} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <CommonSwitch name={'IsDefaultChecked'} value={AllFieldData[ind].IsDefaultChecked} id={'IsDefaultChecked'} onChange={(event) => this.handleChangeField('', event, item.ID, ind)} label={'IsDefaultChecked'} checked={AllFieldData[ind].IsDefaultChecked == undefined ? false : true} />
                                        </div>
                                        <div style={{ width: '200px' }}>
                                            <TextField name={'ButtonType'} id={'ButtonType'} onChange={(e) => this.handleChangeField(e, '', item.ID, ind)} value={AllFieldData[ind].ButtonType} helper={'Please Enter ButtonType'} IsValid={IsValid} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }

                    <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <DialogActions>
                            <CommonButton IsLoading={IsLoading} onClick={() => this.handleValidate()} color="primary">{'Save'}</CommonButton>
                            <CommonButton onClick={() => this.props.onClose()} color="secondary">{'Close'}</CommonButton>
                        </DialogActions>
                    </Grid>
                </Grid>
        );
    }
}
export default ScreensForm;
