
import React from 'react';
import TextField from "../../Camponets/TextField/TextField";
import CommonButton from "../../Camponets/Button/CommonButton";
import CommonSwitch from "../../Camponets/Switch/CommonSwitch";
import Grid from '@material-ui/core/Grid';
import * as APIController from '../../APIs/APIController.js';
import { CircularProgress } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions';
import DropDown from "../../Camponets/Select/DropDown";
import CheckBox from "../../Camponets/CheckBox/CheckBox";
import Notification from '../../Camponets/Notification/Notification.js';

class StoredProcedures extends React.Component {
    state = {
        ProjectName: '',
        AllTablesData: [],

        ControllerID: '',
        ProjectNameOption: [],
        ConfigureTablesOption: [],
        ControllersOption: [],

        touched: false,
        IsValid: '',
        option: [],
        IsLoading: false,
        IsloadData: false,
        ShowNotification: "",
        Message: '',
        ToastType: '',
    }

    componentDidMount() {

        this.wsGetProjectForDropdown()

    }

    wsGetProjectForDropdown() {
        APIController.GetProjectForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ProjectNameOption: response.object.data });

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(true, response.error.message,"error");
                }
            });
    };

    wsGetAllTables(ProjectId) {
        APIController.GetAllTables(ProjectId)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ AllTablesData: response.object.data, });

                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(true, response.error.message,"error");
                }
            });
    };
    wsGenerateStoredProcedure(Name) {
        APIController.GenerateStoredProcedure(Name)
            .then((response) => {
                if (response.object.status == 1) {
                    this.handleNotification(true, response.object.message,)
                }
                else if (response.object.status ==0) {
                    this.handleNotification(true, response.object.message,"error");
                }
                this.wsGetAllTables(this.state.projectID)
            });
    };


    handleChange = (e, index, Name) => {
       
        if (e.target.name == "ProjectName") {
            const projectID = e.target.value
            this.wsGetAllTables(projectID)
            this.setState({ [e.target.value]: e.target.value });
            this.setState({ [e.target.name]: e.target.value });
        } else {
          
            this.setState({ AllTablesData: this.state.AllTablesData.map((item, idx) => idx != index ? item : { ...item, 'IsChecked': e.target.checked==true ? 1 : 0 }) })
            this.wsGenerateStoredProcedure(Name)

        }

    }

    handleValidate() {
        if (this.state.ProjectName == '') {
            this.setState({ IsValid: true, })
        }
        else {

            //Add Edit Api Here
            this.wsSaveConfiguredTables(this.state.ProjectName, this.state.ConfigureTablesOption,)
        }

    }

    handleNotification (val, msg, type)  {
        this.setState({ ShowNotification: val, ToastType: type, Message: msg })
    };


    render() {
        const { ProjectName, AllTablesData,Message,ToastType,ShowNotification, TableName, ConfigureTablesOption, ControllerID, ControllersOption, Controller, StoredProcedure, Singular, Plural, ControllerOption, ProjectNameOption, StoredProcedureOption, IsValid, IsLoading, IsloadData, option } = this.state;
        if (IsloadData)
            return <Grid container lg={12} justify='center' style={{ width: 150 }}>
                <CircularProgress />
            </Grid>
        else return (
            <>
                {ShowNotification &&
                    <Notification
                        handleClose={this.handleNotification.bind(this)}
                        message={Message}
                        variant={ToastType}
                    />}
                <form onSubmit={(e) => { e.preventDefault(); this.handleValidate(); }} style={{boxShadow:' rgba(0, 0, 0, 0.35) 0px 5px 15px',margin:"20px",borderRadius:'10px'}}>
                    <h2 style={{ margin: "20px",color:'#0d5a2e', fontSize:'22px',paddingTop:'20px' }} >Stored Procedure</h2>
                    <Grid container lg={12} style={{ width: "25%", margin: "20px", }}>
                        <Grid container xl={12} md={12} sm={6} xs={12}>
                            <DropDown name={'ProjectName'} value={ProjectName} id={'Select Project Name'} onChange={this.handleChange} optionText={ProjectNameOption} helper={'Select Project Name'} IsValid={IsValid} />
                        </Grid>
                        {AllTablesData.map((ele, index) => {
                            return (
                                <Grid container xl={12} xs={12} >

                                    <Grid container xl={6} md={6} sm={6} xs={6}>
                                        <CheckBox color='green' disabled={ele.IsChecked == 1 ? true : false} checked={ele.IsChecked==1?true:false} onChange={(e) => this.handleChange(e,index, ele.Name)} label={ele.Name} />
                                    </Grid>
                                </Grid>
                            )
                        })}

                        <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <DialogActions>
                                {/* <CommonButton IsLoading={IsLoading} type='submit' color="primary">{'Save'}</CommonButton> */}
                                {/* <CommonButton onClick={() => this.props.onClose()} color="secondary">{'Close'}</CommonButton> */}
                            </DialogActions>
                        </Grid>
                    </Grid>
                </form>
            </>
        );
    }
}
export default StoredProcedures;
