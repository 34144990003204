import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TextField } from '@material-ui/core';
import CommonStyle from "../../Camponets/config/CommonStyle";


function TextFields(props) {
    const { classes, onChange,onBlur, type, maxLength, name, placeholder, inputProps, rows, disabled, value, id, helper, IsValid, multiline, children } = props;

    return (
        <Fragment>
            <FormControl className={classes.formControl} aria-describedby="name-helper-text">
                <TextField
                    id={id}
                    disabled={disabled != undefined}
                    className={classes.textField}
                    value={value}
                    onBlur={onBlur}
                    inputProps={inputProps}
                    type={type}
                    name={name}
                    label={value == '' && IsValid == true ? '' : id}
                    rows={rows}
                    onChange={onChange}
                    onInput={(e) => {
                        if (type == 'tel' && e.target.value.length != 0) {
                            if (!isNaN(e.target.value)) {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxLength == undefined ? 14 : maxLength)
                            }
                            else {
                                e.target.value = ''
                            }
                        }
                        else {
                            e.target.value = e.target.value
                        }
                    }}
                    placeholder={placeholder}
                    multiline={multiline != undefined && true}
                    error={value == '' && IsValid == true ? true : false}
                    variant="outlined"
                    {...props} >
                    {children}
                </TextField>

                {helper && value == '' && IsValid == true && <FormHelperText className={classes.errorMsg} id="name-helper-text">{helper}</FormHelperText>}
            </FormControl>
        </Fragment>
    );

}

TextFields.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(CommonStyle)(TextFields);