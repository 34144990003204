


const AppConstant = {
   StickyColor: '#fff',
   Pagination: [10, 25, 50, 100, 200, 500, 1000],
   DefaultSort: 'DESC',
   DefaultRecordsPerPage: 25,
}
const chipData = [
   { 'color': "#a94442", 'backgroundColor': '#f2dede', 'value': "NO", name: "NO", width: '60px' },
   { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': "YES", name: 'YES', width: '60px' },
   { 'color': "#a94442", 'backgroundColor': '#f2dede', 'value': "No", name: "NO", width: '60px' },
   { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': "Yes", name: 'YES', width: '60px' },
   { 'color': "#1a1110c7", 'backgroundColor': '', 'value': "WorkOrders", name: 'WorkOrders', width: '60px' },
   { 'color': "#20b756", 'backgroundColor': '#12df2942', 'value': '1', name: 'Created', width: '100%' },
   { 'color': "#d582a4", 'backgroundColor': '#df12ac4a', 'value': '2', name: 'Acknowledged', width: '100%' },
   { 'color': "#0a34b1b8", 'backgroundColor': '#1237df4a', 'value': '3', name: 'Assigned', width: '100%' },
   { 'color': "#6D225C", 'backgroundColor': '#DD92CB', 'value': '4', name: 'Started', width: '100%' },
   { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': '5', name: 'Completed', width: '100%' },
   { 'color': "#bf762a", 'backgroundColor': '#ffa2337a', 'value': '6', name: 'Closed', width: '100%' },
   { 'color': "#a94442", 'backgroundColor': '#f2dede', 'value': '7', name: 'Cancelled', width: '100%' },
   { 'color': "black", 'backgroundColor': '#80808099', 'value': "" }]

const TableColor = { main: '#0d5a2e', buttonFontColor: "#ffff" }

// const rolesAndRights = () => {
//    let UserRoleID = localStorage.getItem("localStorageUserRoleID")

//    let Roles = RolesData.filter((item) => { return item.RoleID.toLowerCase() == UserRoleID })

//    const url = new URL(window.location.href);
//    const path = url.pathname;

//    if (Roles.length) {
//       const rights = Roles[0].Screens;
//       const pageOperations = rights.find(right => right.Path == path);

//       if (pageOperations) {
//          return pageOperations.Operations.split(',').map(op => parseInt(op));
//       }
//    }

//    return [];
// }

// const rolesAndRightsGetNames = (operations, type) => {
//    return operations.indexOf(type) == -1 ? true : false
// }

// const PermissionList = { "List": 1, "Add": 2, "Edit": 3, "Delete": 4 }

// const UserType = [
//    { ID: "518432D7-A332-46A8-BDF4-913D184AF91F", No: 1, name: "Super Admin" },
//    { ID: "D7053231-64D6-41EB-9BAA-D316B04352B8", No: 2, name: "Admin" },
//    { ID: "D90A5348-FF2A-4680-A5C0-7E33CB460CEC", No: 3, name: "Tenant Admin" },
//    { ID: "B7D58A99-46CF-4B19-9095-1A2C60666859", No: 4, name: "User" },
// ]

const IsEmpty = (data) => {
   return (data == null || data.length === 0 || data == undefined || data == '');
}


export { AppConstant, chipData, TableColor,  IsEmpty }
