import { CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import * as APIController from '../../APIs/APIController.js';
import CommonButton from "../../Camponets/Button/CommonButton.js";
import DropDown from "../../Camponets/Select/DropDown.js";
import TextField from "../../Camponets/TextField/TextField.js";
import CommonSwitch from "../../Camponets/Switch/CommonSwitch.js";

class ControllerForm extends React.Component {
    state = {
        ID: '',
        ProjectName: '',
        Name: '',
        EditID: '0',
        IsActive: true,
        ProjectOption: [],
        IsValid: false,
        IsLoading: false,
        IsloadData: false
    }

    componentDidMount() {
        this.props.EditID == 0 ? this.wsGetProjectForDropdown() : this.wsGetControllerByID(this.props.EditID);
    }

    wsGetProjectForDropdown() {
        APIController.GetProjectForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ ProjectOption: response.object.data })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    handleValidate() {
        if (this.state.Name == '') {
            this.setState({ IsValid: true, })
        } else {
            this.wsAddEditController(this.props.EditID, this.state.ProjectName, this.state.Name, this.state.IsActive,)
        }
    }

    wsGetControllerByID(ID) {
        this.setState({ IsloadData: true })
        APIController.GetControllerByID(ID)
            .then((response) => {
                if (response.error == null) {
                    this.setState({
                        ProjectName: response.object.data.ProjectID,
                        Name: response.object.data.Name,
                        ProjectOption: (response.object.data.ProjectName),
                    })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
                this.setState({ IsloadData: false })
            })
    }

    wsAddEditController(ID, ProjectID, Name, IsActive,) {
        this.setState({ IsLoading: true })
        APIController.AddEditController(ID, ProjectID, Name, IsActive,)
            .then((response) => {
                this.setState({ IsLoading: false })
                if (response.error == null) {
                    this.props.handlebind()
                    this.props.handleNotification(true, response.object.message,)
                    this.props.onClose()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.props.handleNotification(true, response.error.message, 'error')
                }
            })
    }
    handleNotification(val, msg, type) {
        this.setState({ ShowNotification: val, Message: msg, ToastType: type })
    }

    render() {
        const { ProjectName, ProjectOption, Name, IsActive, IsValid, IsLoading, IsloadData } = this.state;
        return (
            IsloadData ? <CircularProgress /> :
                <Grid container lg={12} justify='center' >
                    <Grid container justify="space-between">
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <DropDown name={'ProjectName'} value={ProjectName} id={'Project Name'} onChange={this.handleChange} optionText={ProjectOption} helper={'Select Project Name'} IsValid={IsValid} />
                        </Grid>
                        <Grid container lg={6} xl={6} md={6} sm={12} xs={12}>
                            <TextField name={'Name'} id={'Name'} onChange={this.handleChange} value={Name} helper={'Please Enter Name'} IsValid={IsValid} />
                        </Grid>
                    </Grid>

                    <CommonSwitch name={'IsActive'} value={IsActive} id={'IsActive'} onChange={(e) => this.setState({ IsActive: e.target.checked })} label={'Is Active'} checked={IsActive} />

                    <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <DialogActions>
                            <CommonButton IsLoading={IsLoading} onClick={() => this.handleValidate()} color="primary">{'Save'}</CommonButton>
                            <CommonButton onClick={() => this.props.onClose()} color="secondary">{'Close'}</CommonButton>
                        </DialogActions>
                    </Grid>
                </Grid>

        );
    }
}
export default ControllerForm;
